import React from "../../_snowpack/pkg/react.js";
import {useSelector} from "../../_snowpack/pkg/react-redux.js";
import useResizeObserver from "../../_snowpack/pkg/use-resize-observer.js";
import {Grid} from "../../_snowpack/pkg/@mui/material.js";
import {selectedImageSelector} from "../selectors.js";
import ImageMaster from "./ImageMaster.js";
function ImageMain() {
  const selectedImage = useSelector(selectedImageSelector);
  const {ref: containerRef, width = null, height = null} = useResizeObserver();
  if (selectedImage === null) {
    return /* @__PURE__ */ React.createElement("div", {
      id: "UnselectedImagePlaceholder"
    });
  }
  return /* @__PURE__ */ React.createElement(Grid, {
    id: "image-main-wrapper",
    container: true,
    sx: {
      width: "calc(100% - 240px)",
      position: "relative",
      left: 240,
      height: "100%",
      flexDirection: "column",
      bgcolor: "background.default"
    }
  }, /* @__PURE__ */ React.createElement("div", {
    ref: containerRef,
    style: {flexGrow: 1}
  }, /* @__PURE__ */ React.createElement(ImageMaster, {
    width,
    height
  })));
}
export default ImageMain;
