
      function getAbsoluteUrl(relativeUrl) {
        const publicPath = __webpack_public_path__;
        let url = '';
        if (!publicPath || publicPath.indexOf('://') < 0) {
          url += window.location.protocol + '//' + window.location.host;
        }
        if (publicPath) {
          url += publicPath;
        } else {
          url += '/';
        }
        return url + relativeUrl;
      }
import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';
({ url: getAbsoluteUrl('dist/store.js'), env: __SNOWPACK_ENV__ }).env = __SNOWPACK_ENV__;

import {configureStore} from "../_snowpack/pkg/@reduxjs/toolkit.js";
import createSagaMiddleware from "../_snowpack/pkg/redux-saga.js";
import createWorkerMiddleware from "./middleware/web-worker.js";
import {rtkQueryErrorLogger} from "./middleware/api-error-handler.js";
import reduxWebsocket from "../_snowpack/pkg/@giantmachines/redux-websocket.js";
import rootSaga from "./sagas/index.js";
import imagesSlice from "./reducers/imagesSlice.js";
import toastSlice from "./reducers/toastSlice.js";
import walkthroughSlice from "./reducers/walkthroughSlice.js";
import {authApi, imageQueryApi} from "./api.js";
const sagaMiddleware = createSagaMiddleware();
const worker = new Worker(new URL("./image-processor-worker.js", ({ url: getAbsoluteUrl('dist/store.js'), env: __SNOWPACK_ENV__ }).url), {
  type: "module"
});
const workerMiddleware = createWorkerMiddleware(worker);
const store = configureStore({
  reducer: {
    [imagesSlice.name]: imagesSlice.reducer,
    [toastSlice.name]: toastSlice.reducer,
    [walkthroughSlice.name]: walkthroughSlice.reducer,
    [imageQueryApi.reducerPath]: imageQueryApi.reducer,
    [authApi.reducerPath]: authApi.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ["IMAGE_PROCESSING_STARTED", "IMAGE_PROCESSING_COMPLETED"]
    }
  }).concat([
    rtkQueryErrorLogger,
    workerMiddleware,
    sagaMiddleware,
    imageQueryApi.middleware,
    reduxWebsocket()
  ])
});
sagaMiddleware.run(rootSaga);
export default store;
