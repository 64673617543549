import React, {useState} from "../../_snowpack/pkg/react.js";
import Joyride from "../../_snowpack/pkg/react-joyride.js";
import localforage from "../../_snowpack/pkg/localforage.js";
import steps from "./WalkthroughSteps.js";
import {ImageCanvas} from "../model/ImageCanvas.js";
import imagesSlice from "../reducers/imagesSlice.js";
import walkthroughSlice, {rootSelector} from "../reducers/walkthroughSlice.js";
import {useDispatch, useSelector} from "../../_snowpack/pkg/react-redux.js";
export function Walkthrough(props) {
  const dispatch = useDispatch();
  const [helpers, setHelpers] = useState(null);
  const state = useSelector(rootSelector);
  const getHelpers = (helpers2) => {
    helpers2.next = () => dispatch(walkthroughSlice.actions.clickedNext());
    setHelpers(helpers2);
  };
  return /* @__PURE__ */ React.createElement(Joyride, {
    debug: true,
    continuous: true,
    disableScrolling: true,
    hideBackButton: true,
    stepIndex: state.stepIndex,
    getHelpers,
    run: state.run,
    scrollToFirstStep: true,
    showProgress: true,
    showSkipButton: false,
    steps,
    styles: {
      options: {
        zIndex: 1e4
      }
    }
  });
}
function importExampleImage(dispatch) {
  const id = "EXAMPLE_IMAGE_1";
  const img = new Image();
  const imgUrl = "/example_images/example1.JPG";
  img.addEventListener("load", async (ev) => {
    const canvas = new ImageCanvas("#canvas-workbench");
    if (canvas === null || canvas.canvas === null) {
      return;
    }
    canvas.canvas.width = img.width;
    canvas.canvas.height = img.height;
    canvas.context?.drawImage(img, 0, 0);
    const imgData = await localforage.setItem(id, canvas.context?.getImageData(0, 0, img.width, img.height));
    const image = {
      id,
      fileName: "Example Image",
      fileType: "image/jpeg",
      width: img.width,
      height: img.height,
      imgUrl,
      isLandscape: img.width >= img.height,
      processedId: null,
      extractedPoints: null,
      isWaiting: true,
      imageQueryResponse: null,
      error: null,
      isWalkthrough: true
    };
    dispatch(imagesSlice.actions.imageAdded(image));
  });
  img.src = imgUrl;
}
