import { c as createCommonjsModule } from './common/_commonjsHelpers-4f955397.js';

var GeoNumber_1 = createCommonjsModule(function (module, exports) {
/**
 * GeoNumber module
 * @module GeoNumber
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeoNumber = void 0;
var DIRECTION = {
    EAST: 'E',
    SOUTH: 'S',
    WEST: 'W',
    NORTH: 'N',
};
/**
 * class to represent a single decimal latitude or longitude
 *
 * @class GeoNumber
 */
var GeoNumber = /** @class */ (function () {
    /**
     * @constructor module:GeoNumber.GeoNumber
     * @param degree
     * @param isLat
     */
    function GeoNumber(degree, isLat) {
        this._degree = degree;
        this._isLat = isLat;
    }
    Object.defineProperty(GeoNumber.prototype, "degree", {
        /**
         * latitude or longitude as a decimal value
         *
         * @readonly
         * @type {number}
         * @memberof GeoNumber
         */
        get: function () {
            return this._degree;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(GeoNumber.prototype, "isLat", {
        /**
         * boolean value for either latitude or longitude
         *
         * @readonly
         * @type {boolean}
         * @memberof GeoNumber
         */
        get: function () {
            return this._isLat;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(GeoNumber.prototype, "direction", {
        /**
         * directional information, calculated from degree and isLat
         *
         * @readonly
         * @type {string}
         * @memberof GeoNumber
         */
        get: function () {
            if (this.degree === 0) {
                return '';
            }
            if (this.isLat) {
                return this.degree > 0 ? DIRECTION['NORTH'] : DIRECTION['SOUTH'];
            }
            else {
                return this.degree > 0 ? DIRECTION['EAST'] : DIRECTION['WEST'];
            }
        },
        enumerable: false,
        configurable: true
    });
    /**
     * return the number of digits after the decimal point
     *
     * @param value
     * @returns {number}
     */
    GeoNumber.prototype.getDecimalPointLength = function (value) {
        var string = String(value).split('.');
        return string[1] ? string[1].length : 0;
    };
    /**
     * return the rounded number multiplied by a coefficient
     *
     * @param value
     * @param coefficient
     * @returns {number}
     */
    GeoNumber.prototype.getNormalizeNumber = function (value, coefficient) {
        return Math.round(value * coefficient);
    };
    /**
     * return the object parse to dms
     * degree, minute, second are of number
     * direction is string
     *
     * toString() is a string consisting of all the elements.
     * e.g: 40°12′32″N
     *
     * @returns {dms}
     * @memberof GeoNumber
     */
    GeoNumber.prototype.toDms = function () {
        var _this = this;
        var absDegree = Math.abs(this.degree);
        var coefficient = Math.pow(10, this.getDecimalPointLength(absDegree));
        var degree = Math.floor(absDegree);
        var minute = Math.floor(((this.getNormalizeNumber(absDegree, coefficient) -
            this.getNormalizeNumber(degree, coefficient)) *
            60) /
            coefficient);
        var second = (((this.getNormalizeNumber(absDegree, coefficient) -
            this.getNormalizeNumber(degree, coefficient)) *
            60 -
            this.getNormalizeNumber(minute, coefficient)) /
            coefficient) *
            60;
        return {
            degree: degree,
            minute: minute,
            second: second,
            direction: this.direction,
            toString: function () {
                return degree + '°' + minute + '′' + second + '″' + _this.direction;
            },
        };
    };
    /**
     * return the object parse to dmm
     * degree, minute are of number
     * direction is string
     *
     * toString() is a string consisting of all the elements.
     * e.g: 40°18′N
     *
     * @returns {dmm}
     * @memberof GeoNumber
     */
    GeoNumber.prototype.toDmm = function () {
        var _this = this;
        var absDegree = Math.abs(this.degree);
        var coefficient = Math.pow(10, this.getDecimalPointLength(absDegree));
        var degree = Math.floor(absDegree);
        var minute = ((this.getNormalizeNumber(absDegree, coefficient) -
            this.getNormalizeNumber(degree, coefficient)) *
            60) /
            coefficient;
        return {
            degree: degree,
            minute: minute,
            direction: this.direction,
            toString: function () { return degree + '°' + minute + '′' + _this.direction; },
        };
    };
    return GeoNumber;
}());
exports.GeoNumber = GeoNumber;

});

var DecimalConverter_1 = createCommonjsModule(function (module, exports) {
/**
 * DecimalConverter module
 * @module DecimalConverter
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecimalConverter = void 0;

/**
 * class for converting latitude and longitude of decimal number
 *
 * @class DecimalConverter
 */
var DecimalConverter = /** @class */ (function () {
    /**
     * @constructor module:GeoConverter.GeoConverter
     * @param latitude
     * @param longitude
     */
    function DecimalConverter(latitude, longitude) {
        this._latitude = new GeoNumber_1.GeoNumber(latitude, true);
        this._longitude = new GeoNumber_1.GeoNumber(longitude, false);
    }
    Object.defineProperty(DecimalConverter.prototype, "latitude", {
        /**
         * latitude as a GeoNumber
         *
         * @readonly
         * @type {GeoNumber}
         * @memberof DecimalConverter
         */
        get: function () {
            return this._latitude;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DecimalConverter.prototype, "longitude", {
        /**
         *  longitude as a GeoNumber
         *
         * @readonly
         * @type {GeoNumber}
         * @memberof DecimalConverter
         */
        get: function () {
            return this._longitude;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * return the object(latitude, longitude) to dms
     * degree, minute, second are of number
     * direction is string
     *
     * toString() is a string consisting of all the elements.
     * e.g: 40°12′32″N
     *
     * @returns {dms, dms}
     * @memberof DecimalConverter
     */
    DecimalConverter.prototype.toDms = function () {
        return {
            latitude: this.latitude.toDms(),
            longitude: this.longitude.toDms(),
        };
    };
    /**
     * return the object(latitude, longitude) to dmm
     * degree, minute, second are of number
     * direction is string
     *
     * toString() is a string consisting of all the elements.
     * e.g: 40°18′N
     *
     * @returns {dmm, dmm}
     * @memberof DecimalConverter
     */
    DecimalConverter.prototype.toDmm = function () {
        return {
            latitude: this.latitude.toDmm(),
            longitude: this.longitude.toDmm(),
        };
    };
    return DecimalConverter;
}());
exports.DecimalConverter = DecimalConverter;

});

var dist = createCommonjsModule(function (module, exports) {
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeoNumber = exports.DecimalConverter = void 0;

Object.defineProperty(exports, "DecimalConverter", { enumerable: true, get: function () { return DecimalConverter_1.DecimalConverter; } });

Object.defineProperty(exports, "GeoNumber", { enumerable: true, get: function () { return GeoNumber_1.GeoNumber; } });

});

var GeoNumber = dist.GeoNumber;
export { GeoNumber };
