import React from "../../_snowpack/pkg/react.js";
import {useImageTransformContext} from "./ImageTransformContext.js";
export function InteractionCaptureSurface() {
  const {width, height, zoom, setMousePos} = useImageTransformContext();
  const handleMouseMove = React.useCallback((evt) => {
    setMousePos(evt);
    zoom.dragMove(evt);
  }, [setMousePos, zoom]);
  const handleMouseLeave = React.useCallback((evt) => {
    setMousePos(null);
    zoom.dragEnd();
  }, [setMousePos, zoom]);
  return /* @__PURE__ */ React.createElement("rect", {
    width,
    height,
    rx: 14,
    fill: "transparent",
    onTouchStart: zoom.dragStart,
    onTouchMove: zoom.dragMove,
    onTouchEnd: zoom.dragEnd,
    onMouseDown: zoom.dragStart,
    onMouseMove: handleMouseMove,
    onMouseUp: zoom.dragEnd,
    onMouseLeave: handleMouseLeave
  });
}
