import * as React from "../../_snowpack/pkg/react.js";
import {Link} from "../../_snowpack/pkg/@mui/material.js";
import config from "../config.js";
export default function ToastPatreonLink() {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, "You have reached your daily solve request limit. To remove this limit, consider", /* @__PURE__ */ React.createElement(Link, {
    sx: {color: "secondary.main"},
    href: `${config.apiBaseUrlAuth}/authorize/patreon`,
    target: "_blank"
  }, "supporting Solvastro via Patreon"));
}
