
      function getAbsoluteUrl(relativeUrl) {
        const publicPath = __webpack_public_path__;
        let url = '';
        if (!publicPath || publicPath.indexOf('://') < 0) {
          url += window.location.protocol + '//' + window.location.host;
        }
        if (publicPath) {
          url += publicPath;
        } else {
          url += '/';
        }
        return url + relativeUrl;
      }
import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';
({ url: getAbsoluteUrl('dist/index.js'), env: __SNOWPACK_ENV__ }).env = __SNOWPACK_ENV__;

import React from "../_snowpack/pkg/react.js";
import ReactDOM from "../_snowpack/pkg/react-dom.js";
import {Provider} from "../_snowpack/pkg/react-redux.js";
import CssBaseline from "../_snowpack/pkg/@mui/material/CssBaseline.js";
import {StyledEngineProvider} from "../_snowpack/pkg/@mui/material/styles.js";
import {ThemeProvider} from "../_snowpack/pkg/@mui/material/styles.js";
import {SnackbarProvider} from "../_snowpack/pkg/notistack.js";
import App from "./components/App.js";
import store from "./store.js";
import theme from "./theme.js";
ReactDOM.render(/* @__PURE__ */ React.createElement(React.StrictMode, null, /* @__PURE__ */ React.createElement(CssBaseline, {
  enableColorScheme: true
}), /* @__PURE__ */ React.createElement(StyledEngineProvider, {
  injectFirst: true
}, /* @__PURE__ */ React.createElement(ThemeProvider, {
  theme
}, /* @__PURE__ */ React.createElement(Provider, {
  store
}, /* @__PURE__ */ React.createElement(SnackbarProvider, null, /* @__PURE__ */ React.createElement(App, null)))))), document.getElementById("root"));
if (undefined /* [snowpack] ({ url: getAbsoluteUrl('dist/index.js'), env: __SNOWPACK_ENV__ }).hot */ ) {
  undefined /* [snowpack] ({ url: getAbsoluteUrl('dist/index.js'), env: __SNOWPACK_ENV__ }).hot */ .accept();
}
