import React from "../../_snowpack/pkg/react.js";
import {GlyphCircle} from "../../_snowpack/pkg/@visx/glyph.js";
import {Text} from "../../_snowpack/pkg/@visx/text.js";
export function QueryPointMarker({
  position,
  index,
  solPoint
}) {
  let color = "gray";
  let labelText = (index + 1).toString();
  let match = null;
  if (solPoint && solPoint !== "Distractor" && solPoint !== "Conflict") {
    color = "green";
    labelText = solPoint.Match.index_name;
    match = {
      x: solPoint.Match.index_x,
      y: solPoint.Match.index_y
    };
  }
  return /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement(GlyphCircle, {
    left: position.x,
    top: position.y,
    size: 40,
    stroke: color,
    strokeOpacity: 0.7,
    fill: "none"
  }), match && /* @__PURE__ */ React.createElement(GlyphCircle, {
    left: match.x,
    top: match.y,
    size: 40,
    stroke: "cyan",
    strokeOpacity: 0.7,
    fill: "none"
  }), match && /* @__PURE__ */ React.createElement(Text, {
    children: labelText,
    x: match.x - calcLabelOffset(labelText),
    y: match.y + 18,
    fill: "cyan",
    opacity: 0.5
  }));
}
function calcLabelOffset(val) {
  const str = String(val);
  return 2 + 3 * str.length;
}
