import {createTheme} from "../_snowpack/pkg/@mui/material/styles.js";
const theme = createTheme({
  palette: {
    primary: {
      main: "#351E75",
      light: "#536dfe",
      contrastText: "#FFF"
    },
    secondary: {
      main: "#FFF3b0",
      contrastText: "#000"
    },
    success: {
      main: "#28965a",
      contrastText: "#fff"
    },
    warning: {
      main: "#ff6978",
      contrastText: "#fff"
    },
    info: {
      main: "#4ea699",
      contrastText: "#fff"
    },
    common: {
      black: "#060633"
    },
    background: {
      default: "#191620",
      paper: "#333"
    },
    text: {
      primary: "#FFF",
      secondary: "rgba(123,123,63,0.7)"
    }
  },
  typography: {
    h1: {
      fontFamily: "Philosopher"
    },
    h2: {
      fontFamily: "Philosopher"
    },
    h3: {
      fontFamily: "Philosopher"
    },
    h4: {
      fontFamily: "Philosopher"
    },
    h5: {
      fontFamily: "Oswald"
    },
    h6: {
      fontFamily: "Oswald"
    },
    fontFamily: "Roboto Condensed"
  }
});
export default theme;
