import React from "../../_snowpack/pkg/react.js";
import {useDispatch, useSelector} from "../../_snowpack/pkg/react-redux.js";
import {useSnackbar} from "../../_snowpack/pkg/notistack.js";
import {actions} from "../reducers/toastSlice.js";
const {removeSnackbar} = actions;
let displayed = [];
const useNotifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((store) => store.toast.notifications || []);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };
  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };
  React.useEffect(() => {
    notifications.forEach(({key, message, options = {}, dismissed = false}) => {
      if (dismissed) {
        closeSnackbar(key);
        return;
      }
      if (displayed.includes(key))
        return;
      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          dispatch(removeSnackbar(myKey));
          removeDisplayed(myKey);
        }
      });
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
};
export default useNotifier;
