export const KEYWORD_LENGTH = 8;
export const DEFAULT_VALUE_LENGTH = 20;
export const LINE_LENGTH = 80;
export const BLOCK_LENGTH = 2880;
export const KEYWORDS_NO_EQUALS = ["COMMENT", "HISTORY"];
export const MAP_STRING_KEYWORD_PREFIXES_TO_STRING_LENGTH = [
  {
    prefix: "CTYPE",
    len: 12
  },
  {
    prefix: "CUNIT",
    len: 8
  }
];
export class Fits {
  constructor() {
    this.hdus = [
      {
        headers: [
          {
            keyword: "SIMPLE",
            value: "T",
            comment: "Standard FITS file"
          },
          {
            keyword: "BITPIX",
            value: "8"
          },
          {
            keyword: "NAXIS",
            value: "0",
            comment: "Headers only"
          },
          {
            keyword: "EXTEND",
            value: "T",
            comment: "FITS Extensions possible"
          }
        ]
      }
    ];
  }
  addHeader(keyword, value, comment) {
    if (typeof value === "number") {
      value = String(value).toUpperCase().substring(0, 20);
    }
    this.hdus[0].headers.push({
      keyword,
      value,
      comment
    });
  }
  write() {
    const headersToWrite = [...this.hdus[0].headers];
    headersToWrite.push({
      keyword: "HISTORY",
      value: "Created by Solvastro (https://solvastro.com)"
    });
    headersToWrite.push({
      keyword: "DATE",
      value: `'${new Date().toISOString().slice(0, 19)}'`,
      comment: "File creation timestamp"
    });
    headersToWrite.push({
      keyword: "END"
    });
    const formattedHeaderItems = headersToWrite.map((h) => {
      if (KEYWORDS_NO_EQUALS.includes(h.keyword)) {
        return `${h.keyword} ${h.value}`.padEnd(LINE_LENGTH, " ");
      } else {
        const strKwdPrefix = MAP_STRING_KEYWORD_PREFIXES_TO_STRING_LENGTH.find((x) => h.keyword.startsWith(x.prefix));
        if (strKwdPrefix) {
          return `${h.keyword.padEnd(KEYWORD_LENGTH, " ")}= '${h.value.padEnd(strKwdPrefix.len, " ")}' / ${h.comment || "no comment"}`.padEnd(LINE_LENGTH, " ");
        } else {
          if (!h.value) {
            return h.keyword.padEnd(LINE_LENGTH, " ");
          } else {
            return `${h.keyword.padEnd(KEYWORD_LENGTH, " ")}= ${h.value.padStart(DEFAULT_VALUE_LENGTH, " ")} / ${h.comment || "no comment"}`.padEnd(LINE_LENGTH, " ");
          }
        }
      }
    });
    while (formattedHeaderItems.length % (BLOCK_LENGTH / LINE_LENGTH)) {
      formattedHeaderItems.push("".padEnd(LINE_LENGTH, " "));
    }
    return formattedHeaderItems.join("");
  }
}
