import React from "../../_snowpack/pkg/react.js";
import {GlyphDiamond} from "../../_snowpack/pkg/@visx/glyph.js";
import {Text} from "../../_snowpack/pkg/@visx/text.js";
import {useImageTransformContext} from "./ImageTransformContext.js";
export function ReferencePointMarkers() {
  const {zoom, wcs: wcsOrUndef} = useImageTransformContext();
  if (!wcsOrUndef) {
    return /* @__PURE__ */ React.createElement(React.Fragment, null);
  }
  const wcs = wcsOrUndef;
  const crPix = zoom.applyToPoint(wcs.get_crpix());
  const crValOpt = wcs.world_2_pix(wcs.get_crval().to_xyz());
  const crVal = crValOpt ? zoom.applyToPoint(crValOpt) : null;
  return /* @__PURE__ */ React.createElement("g", null, crVal && /* @__PURE__ */ React.createElement(GlyphDiamond, {
    left: crVal.x,
    top: crVal.y,
    size: 70,
    stroke: "purple",
    strokeOpacity: 1,
    fill: "none"
  }), crVal && /* @__PURE__ */ React.createElement(Text, {
    children: "CRVAL",
    x: crVal.x - calcLabelOffset("CRVAL"),
    y: crVal.y - 18,
    fill: "purple",
    opacity: 0.8
  }));
}
function calcLabelOffset(val) {
  const str = String(val);
  return 2 + 3 * str.length;
}
