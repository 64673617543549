import {call, put, take} from "../../_snowpack/pkg/redux-saga/effects.js";
import {connect, disconnect} from "../../_snowpack/pkg/@giantmachines/redux-websocket.js";
import config from "../config.js";
import {authApi} from "../api.js";
export function* userLifecycleSaga() {
  do {
    yield put(authApi.endpoints.me.initiate());
    let response = yield take(rtkResMatcher(authApi, "me"));
    let user = response && !response.error && response.payload;
    if (!user) {
      const loginAction = yield take("AUTH.LOGIN.REQUEST");
      const {payload: channel = ""} = loginAction;
      if (channel === "local") {
      } else {
        const loginUrl = `${config.apiBaseUrlAuth}/login`;
        yield call(window.open, loginUrl);
        const loginResult = yield call(loginMessageSource);
      }
    } else {
      yield put(connect(config.wsApiUrl));
      yield take(["AUTH.LOGOUT.REQUEST"]);
      yield call(fetch, `${config.apiBaseUrlAuth}/logout`);
      yield put(authApi.endpoints.me.initiate());
      yield put(disconnect());
    }
  } while (true);
}
;
function rtkResMatcher(api, endpointName) {
  return (action) => {
    let [apiPath, strQuery, responseActionType] = action.type.split("/");
    if (apiPath !== api.reducerPath) {
      return false;
    }
    if (strQuery !== "executeQuery") {
      return false;
    }
    if (responseActionType === "pending") {
      return false;
    }
    if (String(action?.error?.message).startsWith("Aborted")) {
      return false;
    }
    if (action?.meta?.arg?.type !== "query") {
      return false;
    }
    if (action?.meta?.arg?.endpointName !== endpointName) {
      return false;
    }
    return true;
  };
}
function loginMessageSource() {
  return new Promise((resolve) => {
    const handler = (event) => {
      let src = event?.source;
      if (src?.opener !== window) {
        return;
      }
      let message;
      try {
        message = JSON.parse(event.data);
      } catch (e) {
        return;
      }
      if (message.type !== "AUTH.LOGIN.RESPONSE" && message !== "authTokenSet") {
        return;
      }
      window.removeEventListener("message", handler);
      resolve(message);
    };
    window.addEventListener("message", handler);
  });
}
