import React from "../../_snowpack/pkg/react.js";
import {useSelector} from "../../_snowpack/pkg/react-redux.js";
import {Button} from "../../_snowpack/pkg/@mui/material.js";
import {Save as SaveIcon} from "../../_snowpack/pkg/@mui/icons-material.js";
import {selectedImageSelector} from "../selectors.js";
import {useImageTransformContext} from "./ImageTransformContext.js";
import FileSaver from "../../_snowpack/pkg/file-saver.js";
import {radians_to_degrees} from "../math-utils.js";
import {Fits} from "../fits.js";
function ImageDetails() {
  const selectedImage = useSelector(selectedImageSelector);
  const {solution, wcs} = useImageTransformContext();
  if (!selectedImage || !wcs) {
    return /* @__PURE__ */ React.createElement("div", null);
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, solution && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Button, {
    className: "walkthrough-target-download-wcs",
    variant: "contained",
    color: "secondary",
    size: "small",
    endIcon: /* @__PURE__ */ React.createElement(SaveIcon, null),
    onClick: () => saveAsFits(solution, selectedImage)
  }, "FITS WCS"), /* @__PURE__ */ React.createElement(Button, {
    variant: "contained",
    color: "secondary",
    size: "small",
    endIcon: /* @__PURE__ */ React.createElement(SaveIcon, null),
    onClick: () => saveAsJson(solution),
    sx: {ml: 1}
  }, "JSON WCS")));
}
export default ImageDetails;
function saveAsJson(solution) {
  const wcsParams = {
    crpix: solution.wcs.crpix,
    crval: {
      ra: radians_to_degrees(solution.wcs.crval.ra),
      dec: radians_to_degrees(solution.wcs.crval.dec)
    },
    cd: solution.wcs.cd,
    sip_params: solution.wcs.sip_params
  };
  const jsonBlob = new Blob([JSON.stringify(wcsParams)], {type: "application/json"});
  return FileSaver.saveAs(jsonBlob, "wcs.json");
}
function saveAsFits(solution, image) {
  const wcsParams = {
    crpix: solution.wcs.crpix,
    crval: {
      ra: radians_to_degrees(solution.wcs.crval.ra),
      dec: radians_to_degrees(solution.wcs.crval.dec)
    },
    cd: solution.wcs.cd,
    sip_params: solution.wcs.sip_params
  };
  const fits = new Fits();
  fits.addHeader("WCSAXES", "2");
  if (solution.wcs.sip_params) {
    fits.addHeader("CTYPE1", "RA---TAN-SIP", "Gnomonic (TAN) Projection + SIP");
    fits.addHeader("CTYPE2", "DEC--TAN-SIP", "Gnomonic (TAN) Projection + SIP");
  } else {
    fits.addHeader("CTYPE1", "RA---TAN", "Gnomonic (TAN) Projection");
    fits.addHeader("CTYPE2", "DEC--TAN", "Gnomonic (TAN) Projection");
  }
  fits.addHeader("EQUINOX", "2000.0", "Equator and equinox of J2000.0");
  fits.addHeader("LONPOLE", "180.0", "Native longitude of celestial pole");
  fits.addHeader("LATPOLE", "0.0", "Ecliptic latitude of native pole");
  fits.addHeader("CRVAL1", solution.wcs.crval.ra, "Right Ascension (world) of ref point");
  fits.addHeader("CRVAL2", solution.wcs.crval.dec, "Declination (world) of ref point");
  fits.addHeader("CRPIX1", solution.wcs.crpix[0], "x coord (image) of ref point");
  fits.addHeader("CRPIX2", solution.wcs.crpix[1], "y coord (image) of ref point");
  fits.addHeader("CUNIT1", "deg");
  fits.addHeader("CUNIT2", "deg");
  fits.addHeader("CD1_1", solution.wcs.cd[0], "WCS CD transformation matrix");
  fits.addHeader("CD1_2", solution.wcs.cd[1]);
  fits.addHeader("CD2_1", solution.wcs.cd[2]);
  fits.addHeader("CD2_2", solution.wcs.cd[3]);
  fits.addHeader("IMAGEW", image.width, "image width");
  fits.addHeader("IMAGEH", image.height, "image height");
  if (solution.wcs.sip_params) {
    fits.addHeader("A_ORDER", 2, "Polynomial order, axis 1");
    fits.addHeader("A_0_0", solution.wcs.sip_params[0][0], "polynomial terms of image distortion correction");
    fits.addHeader("A_0_1", solution.wcs.sip_params[0][1]);
    fits.addHeader("A_0_2", solution.wcs.sip_params[0][2]);
    fits.addHeader("A_1_0", solution.wcs.sip_params[0][3]);
    fits.addHeader("A_1_1", solution.wcs.sip_params[0][4]);
    fits.addHeader("A_2_0", solution.wcs.sip_params[0][5]);
    fits.addHeader("B_ORDER", 2, "Polynomial order, axis 2");
    fits.addHeader("B_0_0", solution.wcs.sip_params[1][0]);
    fits.addHeader("B_0_1", solution.wcs.sip_params[1][1]);
    fits.addHeader("B_0_2", solution.wcs.sip_params[1][2]);
    fits.addHeader("B_1_0", solution.wcs.sip_params[1][3]);
    fits.addHeader("B_1_1", solution.wcs.sip_params[1][4]);
    fits.addHeader("B_2_0", solution.wcs.sip_params[1][5]);
    fits.addHeader("AP_ORDER", 2, "Polynomial order, axis 1, inverse");
    fits.addHeader("AP_0_0", solution.wcs.sip_params[2][0], "polynomial terms of inverse image distortion correction");
    fits.addHeader("AP_0_1", solution.wcs.sip_params[2][1]);
    fits.addHeader("AP_0_2", solution.wcs.sip_params[2][2]);
    fits.addHeader("AP_1_0", solution.wcs.sip_params[2][3]);
    fits.addHeader("AP_1_1", solution.wcs.sip_params[2][4]);
    fits.addHeader("AP_2_0", solution.wcs.sip_params[2][5]);
    fits.addHeader("BP_ORDER", 2, "Polynomial order, axis 2, inverse");
    fits.addHeader("BP_0_0", solution.wcs.sip_params[3][0]);
    fits.addHeader("BP_0_1", solution.wcs.sip_params[3][1]);
    fits.addHeader("BP_0_2", solution.wcs.sip_params[3][2]);
    fits.addHeader("BP_1_0", solution.wcs.sip_params[3][3]);
    fits.addHeader("BP_1_1", solution.wcs.sip_params[3][4]);
    fits.addHeader("BP_2_0", solution.wcs.sip_params[3][5]);
  }
  const jsonBlob = new Blob([fits.write()], {type: "application/fits"});
  return FileSaver.saveAs(jsonBlob, "wcs.fits");
}
function WcsDetails({solution}) {
  return /* @__PURE__ */ React.createElement("div", null, solution.wcs.crpix);
}
