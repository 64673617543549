export const RAD_TO_DEC_FACTOR = 180 / Math.PI;
export const DEC_TO_RAD_FACTOR = Math.PI / 180;
export function radians_to_degrees(radians) {
  return radians * RAD_TO_DEC_FACTOR;
}
export function degrees_to_radians(degrees) {
  return degrees * DEC_TO_RAD_FACTOR;
}
export function radians_to_hms(radians) {
  const degrees = radians_to_degrees(radians);
  const hours = degrees / 15;
  const minutes = (hours - Math.floor(hours)) * 60;
  const seconds = (minutes - Math.floor(minutes)) * 60;
  return {
    h: Math.floor(hours),
    m: Math.floor(minutes),
    s: seconds
  };
}
export function extent(values, valueof) {
  let min;
  let max;
  if (valueof === void 0) {
    for (const value of values) {
      if (value != null) {
        if (min === void 0) {
          if (value >= value)
            min = max = value;
        } else {
          if (min > value)
            min = value;
          if (max === void 0 || max < value)
            max = value;
        }
      }
    }
  } else {
    min = Number.MAX_VALUE;
    max = Number.MIN_VALUE;
    let value;
    for (let item of values) {
      value = valueof(item);
      if (value != null) {
        if (min === void 0) {
          if (value >= value)
            min = max = value;
        } else {
          if (min > value)
            min = value;
          if (max < value)
            max = value;
        }
      }
    }
  }
  return [Number(min), Number(max)];
}
