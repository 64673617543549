import React from "../../_snowpack/pkg/react.js";
import {CoordCelestial as Celestial, Mtx2x2Js, Point2dJs, WcsTan} from "../wcs-wasm/index.js";
import {degrees_to_radians, extent, radians_to_degrees} from "../math-utils.js";
export const ImageTransformContext = React.createContext(void 0);
export function useImageTransformContext() {
  let context = React.useContext(ImageTransformContext);
  if (context === void 0) {
    throw Error("ImageTransformContext not set");
  }
  return context;
}
export function deriveImageTransformContext(txCtxSrc) {
  const {zoom, width, height, solution} = txCtxSrc;
  if (!solution) {
    return {
      ...txCtxSrc,
      wcs: void 0,
      decExtent: [0, 0],
      raExtent: [0, 0],
      corners: null
    };
  }
  const wcs = wcsTanFromSolution(solution);
  const imagePixTL = zoom.applyInverseToPoint({x: 0, y: 0});
  const imagePixTR = zoom.applyInverseToPoint({x: width, y: 0});
  const imagePixBL = zoom.applyInverseToPoint({x: 0, y: height});
  const imagePixBR = zoom.applyInverseToPoint({x: width, y: height});
  const worldTL = wcs.pix_2_world(new Point2dJs(imagePixTL.x, imagePixTL.y)).to_celestial();
  const worldTR = wcs.pix_2_world(new Point2dJs(imagePixTR.x, imagePixTR.y)).to_celestial();
  const worldBL = wcs.pix_2_world(new Point2dJs(imagePixBL.x, imagePixBL.y)).to_celestial();
  const worldBR = wcs.pix_2_world(new Point2dJs(imagePixBR.x, imagePixBR.y)).to_celestial();
  const corners = [worldTL, worldTR, worldBL, worldBR];
  const raExtent = extent(corners, (i) => radians_to_degrees(i.ra));
  const decExtent = extent(corners, (i) => radians_to_degrees(i.dec));
  const ncpProjected = wcs.world_2_pix(new Celestial(0, degrees_to_radians(90)).to_xyz());
  const scpProjected = wcs.world_2_pix(new Celestial(0, degrees_to_radians(-90)).to_xyz());
  if (ncpProjected && ncpProjected?.x >= 0 && ncpProjected.x <= width && ncpProjected.y >= 0 && ncpProjected.y <= height) {
    decExtent[1] = 90;
  }
  if (scpProjected && scpProjected?.x >= 0 && scpProjected.x <= width && scpProjected.y >= 0 && scpProjected.y <= height) {
    decExtent[0] = -90;
  }
  return {
    ...txCtxSrc,
    wcs,
    corners: {tl: worldTL, tr: worldTR, bl: worldBL, br: worldBR},
    decExtent,
    raExtent
  };
}
export function wcsTanFromSolution(solution) {
  const wcsRaw = solution.wcs;
  const crpix = new Point2dJs(wcsRaw.crpix[0], wcsRaw.crpix[1]);
  const crval = new Celestial(wcsRaw.crval.ra, wcsRaw.crval.dec);
  const cd = new Mtx2x2Js(wcsRaw.cd[0], wcsRaw.cd[2], wcsRaw.cd[1], wcsRaw.cd[3]);
  const wcs = new WcsTan(crpix, crval, cd);
  return wcs;
}
