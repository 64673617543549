import * as React from "../../_snowpack/pkg/react.js";
import {AppBar, Box, Link, Toolbar, IconButton, Typography, Menu, Container, Avatar, Button, Tooltip, MenuItem} from "../../_snowpack/pkg/@mui/material.js";
import {Menu as MenuIcon} from "../../_snowpack/pkg/@mui/icons-material.js";
import config from "../config.js";
import {authApi, imageQueryApi} from "../api.js";
import {useDispatch} from "../../_snowpack/pkg/react-redux.js";
const pages = [];
const settings = [];
export default function Header() {
  const user = authApi.endpoints.me.useQuery();
  const dispatch = useDispatch();
  return /* @__PURE__ */ React.createElement(AppBar, {
    position: "static",
    className: "walkthrough-target-app-header"
  }, /* @__PURE__ */ React.createElement(Container, {
    maxWidth: false
  }, /* @__PURE__ */ React.createElement(Toolbar, {
    disableGutters: true
  }, /* @__PURE__ */ React.createElement(AppTitle, null), /* @__PURE__ */ React.createElement(NavTabs, null), /* @__PURE__ */ React.createElement(AppTitleSmall, null), user.isSuccess ? /* @__PURE__ */ React.createElement(UserMenu, null) : /* @__PURE__ */ React.createElement(Link, {
    className: "walkthrough-target-query-credits",
    sx: {color: "secondary.main"},
    onClick: () => {
      dispatch({type: "AUTH.LOGIN.REQUEST"});
    }
  }, "Sign In"))));
}
;
function UserMenu() {
  const dispatch = useDispatch();
  const user = authApi.useMeQuery();
  const remaining = imageQueryApi.useGetRemainingQueryCountQuery();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Box, {
    className: "walkthrough-target-query-credits",
    sx: {flexGrow: 0, mr: 1}
  }, remaining.data && !remaining.data?.unlimited ? /* @__PURE__ */ React.createElement(Typography, {
    textAlign: "center"
  }, "Query credits: ", remaining.data?.remaining) : ""), /* @__PURE__ */ React.createElement(Box, {
    sx: {flexGrow: 0}
  }, /* @__PURE__ */ React.createElement(Tooltip, {
    title: "Open settings"
  }, /* @__PURE__ */ React.createElement(IconButton, {
    onClick: handleOpenUserMenu,
    sx: {p: 0}
  }, /* @__PURE__ */ React.createElement(Avatar, {
    alt: user.data?.name
  }))), /* @__PURE__ */ React.createElement(Menu, {
    sx: {mt: "45px"},
    anchorEl: anchorElUser,
    anchorOrigin: {
      vertical: "top",
      horizontal: "right"
    },
    keepMounted: true,
    transformOrigin: {
      vertical: "top",
      horizontal: "right"
    },
    open: Boolean(anchorElUser),
    onClose: handleCloseUserMenu
  }, /* @__PURE__ */ React.createElement(MenuItem, null, /* @__PURE__ */ React.createElement(Typography, {
    textAlign: "center"
  }, user.data?.pc ? "Patreon Account: Linked" : /* @__PURE__ */ React.createElement(Link, {
    sx: {color: "secondary.main"},
    href: `${config.apiBaseUrlAuth}/authorize/patreon`
  }, "Connect to Patreon"))), user.data?.pc && /* @__PURE__ */ React.createElement(MenuItem, null, /* @__PURE__ */ React.createElement(Typography, {
    textAlign: "center"
  }, user.data?.ps ? "Subscription: Active" : /* @__PURE__ */ React.createElement(Link, {
    sx: {color: "secondary.main"},
    href: "https://www.patreon.com/scottdonnelly"
  }, "Support Via Patreon"))), user.data ? /* @__PURE__ */ React.createElement(MenuItem, {
    onClick: () => {
      dispatch({type: "AUTH.LOGOUT.REQUEST"});
    }
  }, /* @__PURE__ */ React.createElement(Typography, {
    textAlign: "center"
  }, /* @__PURE__ */ React.createElement(Link, {
    sx: {color: "secondary.main"},
    href: `${config.apiBaseUrlAuth}/logout`
  }, "Log Out"))) : "")));
}
function AppTitle() {
  return /* @__PURE__ */ React.createElement(Typography, {
    variant: "h4",
    noWrap: true,
    color: "secondary.main",
    sx: {
      mr: 2,
      p: 1,
      display: {xs: "none", md: "flex"},
      textShadow: "rgb(254 249 195) 0 0 18px"
    }
  }, "Solvastro");
}
function NavTabs() {
  return /* @__PURE__ */ React.createElement(Box, {
    sx: {flexGrow: 1, display: {xs: "none", md: "flex"}, ml: 4}
  }, pages.map((page) => /* @__PURE__ */ React.createElement(Button, {
    key: page,
    onClick: () => {
    },
    sx: {my: 2, color: "secondary.main", display: "block", position: "relative", bottom: -16, borderBottom: 4, borderRadius: 0}
  }, page)));
}
function AppTitleSmall() {
  return /* @__PURE__ */ React.createElement(Typography, {
    variant: "h6",
    noWrap: true,
    sx: {flexGrow: 1, display: {xs: "flex", md: "none"}}
  }, "Solvastro");
}
function HamburgerMenuSmall() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  return /* @__PURE__ */ React.createElement(Box, {
    sx: {flexGrow: 1, display: {xs: "flex", md: "none"}}
  }, /* @__PURE__ */ React.createElement(IconButton, {
    size: "large",
    "aria-label": "account of current user",
    "aria-controls": "menu-appbar",
    "aria-haspopup": "true",
    onClick: handleOpenNavMenu,
    color: "inherit"
  }, /* @__PURE__ */ React.createElement(MenuIcon, null)), /* @__PURE__ */ React.createElement(Menu, {
    anchorEl: anchorElNav,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    keepMounted: true,
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    open: Boolean(anchorElNav),
    onClose: handleCloseNavMenu,
    sx: {
      display: {xs: "block", md: "none"}
    }
  }, pages.map((page) => /* @__PURE__ */ React.createElement(MenuItem, {
    key: page,
    onClick: handleCloseNavMenu
  }, /* @__PURE__ */ React.createElement(Typography, {
    textAlign: "center"
  }, page)))));
}
