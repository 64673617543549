import React from "../../_snowpack/pkg/react.js";
import {useSelector} from "../../_snowpack/pkg/react-redux.js";
import {Text} from "../../_snowpack/pkg/@visx/text.js";
import {CoordCelestial} from "../wcs-wasm/index.js";
import {selectedImageSelector} from "../selectors.js";
import {degrees_to_radians} from "../math-utils.js";
import {useImageTransformContext} from "./ImageTransformContext.js";
import constellationsRaw from "../data/constellations.json";
const constellations = constellationsRaw.features.map((f) => {
  const cf = {
    ...f,
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: f.geometry.coordinates
    }
  };
  return cf;
});
export function ConstellationLabels() {
  const selectedImage = useSelector(selectedImageSelector);
  const imgWidth = selectedImage?.width || 0;
  const imgHeight = selectedImage?.height || 0;
  const txCtx = useImageTransformContext();
  const {width, height, zoom, wcs: wcsOrUndef, corners, raExtent, decExtent} = txCtx;
  if (!wcsOrUndef || !corners) {
    return /* @__PURE__ */ React.createElement(React.Fragment, null);
  }
  const wcs = wcsOrUndef;
  const allConstellations = constellations.map((constellation) => {
    const [ra, dec] = constellation.geometry.coordinates;
    constellation.properties.xyz = new CoordCelestial(degrees_to_radians(ra), degrees_to_radians(dec)).to_xyz();
    return constellation;
  });
  const imageExtentFilteredConstellations = allConstellations.map((constellation) => {
    constellation.properties.filteredFromImg = constellation.geometry.coordinates[0] <= raExtent[0] || constellation.geometry.coordinates[0] > raExtent[1] || constellation.geometry.coordinates[1] < decExtent[0] || constellation.geometry.coordinates[1] > decExtent[1];
    return constellation;
  });
  const projectedImageFilteredConstellations = imageExtentFilteredConstellations.map((constellation) => {
    constellation.properties.projected = wcs.world_2_pix(constellation.properties.xyz);
    return constellation;
  });
  const imageCroppedProjectedConstellations = projectedImageFilteredConstellations.map((constellation) => {
    constellation.properties.filteredFromImg = constellation.properties.filteredFromImg || !constellation.properties.projected || constellation.properties.projected.x < 0 || constellation.properties.projected.x > imgWidth || constellation.properties.projected.y < 0 || constellation.properties.projected.y > imgHeight;
    return constellation;
  });
  const viewportFilteredConstellations = filterPointsOutsideViewport(imageCroppedProjectedConstellations, zoom, width, height);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, viewportFilteredConstellations.filter((c) => !c.properties.filteredFromViewport).map((constellation, idx) => /* @__PURE__ */ React.createElement(Text, {
    key: idx,
    children: constellation.properties.name,
    x: constellation.properties.viewportPos.x,
    y: constellation.properties.viewportPos.y,
    fill: "gray",
    opacity: 0.5,
    style: {fontSize: "3em", fontWeight: 900},
    verticalAnchor: "middle",
    textAnchor: "middle"
  })));
}
function filterPointsOutsideViewport(points, zoom, width, height) {
  return points.map((point) => {
    if (!point.properties.filteredFromImg) {
      if (point.properties.projected) {
        const pointPosInViewport = zoom.applyToPoint(point.properties.projected);
        point.properties.viewportPos = pointPosInViewport;
        point.properties.filteredFromViewport = !isInRect(pointPosInViewport, 0, width, 0, height);
      } else {
        point.properties.filteredFromViewport = true;
      }
    } else {
      point.properties.filteredFromViewport = true;
    }
    return point;
  });
}
function isInRect(point, minX, maxX, minY, maxY) {
  return point.x >= minX && point.x <= maxX && point.y >= minY && point.y <= maxY;
}
