import {call, put, select, take, takeEvery} from "../../_snowpack/pkg/redux-saga/effects.js";
import localforage from "../../_snowpack/pkg/localforage.js";
import imagesSlice from "../reducers/imagesSlice.js";
import {send} from "../../_snowpack/pkg/@giantmachines/redux-websocket.js";
import {imageObjByIdSelector} from "../selectors.js";
import {actions as walkthroughActions} from "../reducers/walkthroughSlice.js";
export function* preProcessImageSaga(action) {
  const {id, fileType, isWalkthrough} = action.payload;
  if (isWalkthrough) {
    yield put(walkthroughActions.advance(1));
    yield take(walkthroughActions.clickedNext().type);
  }
  const imgData = yield call([localforage, localforage.getItem], id);
  const start = Date.now();
  console.log("image processing started");
  yield put({
    type: "IMAGE_PROCESSING_STARTED",
    payload: {
      imgData,
      id
    },
    meta: {
      WebWorker: true,
      transferList: [imgData.data.buffer]
    }
  });
  yield take(imagesSlice.actions.imagePointsExtracted.type);
  const duration = Date.now() - start;
  console.log("image processing finished. Duration: " + duration / 1e3 + "s");
  if (isWalkthrough) {
    yield put(walkthroughActions.advance(1));
    return;
  }
  let canQuery = false;
  while (!canQuery) {
    let remainingQueriesState = yield select((s) => s.api.queries["getRemainingQueryCount(undefined)"]?.data);
    canQuery = remainingQueriesState?.unlimited || !!remainingQueriesState?.remaining;
    if (!canQuery) {
      const result = yield take(["auth/executeQuery/fulfilled", "api/getRemainingQueryCount/fulfilled"]);
      if (result.type === "auth/executeQuery/fulfilled") {
        if (result?.meta?.arg?.endpointName === "me" && result?.payload?.ps) {
          canQuery = true;
        }
      }
      if (result.type === "api/getRemainingQueryCount/fulfilled") {
        if (result?.payload?.unlimited || (result?.payload?.remaining ?? 0) < 0) {
          canQuery = true;
        }
      }
    }
  }
  yield* doImageMatchQuerySaga(id);
}
function* doImageMatchQueryFromActionSaga(action) {
  yield* doImageMatchQuerySaga(action.payload);
}
function* doImageMatchQuerySaga(id) {
  const imageObj = yield select(imageObjByIdSelector(id));
  const query = {
    filename: `${id}__${imageObj.fileName}`,
    img_w: imageObj.width,
    img_h: imageObj.height,
    points: imageObj.extractedPoints?.map((p) => ({
      x: p.x,
      y: p.y,
      amplitude: p.val,
      radius: 7
    })) || []
  };
  yield put(send({
    action: "solve-image-query",
    data: query
  }));
  const resultRaw = yield take((action) => {
    if (action.type !== "REDUX_WEBSOCKET::MESSAGE") {
      return false;
    }
    if (action.payload?.message) {
      try {
        const message = JSON.parse(action.payload.message);
        return message.action === "solve-image-result";
      } catch (e) {
        return false;
      }
    }
    return false;
  });
  const result = JSON.parse(resultRaw.payload.message).data;
  yield put(imagesSlice.actions.imageQueryResponseFulfilled(result, id));
}
export function* preProcessImageRootSaga() {
  yield takeEvery(imagesSlice.actions.imageAdded.type, preProcessImageSaga);
  yield takeEvery(imagesSlice.actions.imageQueryRequestDispatched.type, doImageMatchQueryFromActionSaga);
}
