
      function getAbsoluteUrl(relativeUrl) {
        const publicPath = __webpack_public_path__;
        let url = '';
        if (!publicPath || publicPath.indexOf('://') < 0) {
          url += window.location.protocol + '//' + window.location.host;
        }
        if (publicPath) {
          url += publicPath;
        } else {
          url += '/';
        }
        return url + relativeUrl;
      }
import * as __SNOWPACK_ENV__ from '../../_snowpack/env.js';
({ url: getAbsoluteUrl('dist/wcs-wasm/index.js'), env: __SNOWPACK_ENV__ }).env = __SNOWPACK_ENV__;


let wasm;

const heap = new Array(32).fill(undefined);

heap.push(undefined, null, true, false);

function getObject(idx) { return heap[idx]; }

let heap_next = heap.length;

function dropObject(idx) {
    if (idx < 36) return;
    heap[idx] = heap_next;
    heap_next = idx;
}

function takeObject(idx) {
    const ret = getObject(idx);
    dropObject(idx);
    return ret;
}

let cachedTextDecoder = new TextDecoder('utf-8', { ignoreBOM: true, fatal: true });

cachedTextDecoder.decode();

let cachegetUint8Memory0 = null;
function getUint8Memory0() {
    if (cachegetUint8Memory0 === null || cachegetUint8Memory0.buffer !== wasm.memory.buffer) {
        cachegetUint8Memory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachegetUint8Memory0;
}

function getStringFromWasm0(ptr, len) {
    return cachedTextDecoder.decode(getUint8Memory0().subarray(ptr, ptr + len));
}

function _assertClass(instance, klass) {
    if (!(instance instanceof klass)) {
        throw new Error(`expected instance of ${klass.name}`);
    }
    return instance.ptr;
}

let cachegetFloat64Memory0 = null;
function getFloat64Memory0() {
    if (cachegetFloat64Memory0 === null || cachegetFloat64Memory0.buffer !== wasm.memory.buffer) {
        cachegetFloat64Memory0 = new Float64Array(wasm.memory.buffer);
    }
    return cachegetFloat64Memory0;
}

let WASM_VECTOR_LEN = 0;

function passArrayF64ToWasm0(arg, malloc) {
    const ptr = malloc(arg.length * 8);
    getFloat64Memory0().set(arg, ptr / 8);
    WASM_VECTOR_LEN = arg.length;
    return ptr;
}
/**
*/
export function set_panic_hook() {
    wasm.set_panic_hook();
}

function addHeapObject(obj) {
    if (heap_next === heap.length) heap.push(heap.length + 1);
    const idx = heap_next;
    heap_next = heap[idx];

    heap[idx] = obj;
    return idx;
}

let cachedTextEncoder = new TextEncoder('utf-8');

const encodeString = (typeof cachedTextEncoder.encodeInto === 'function'
    ? function (arg, view) {
    return cachedTextEncoder.encodeInto(arg, view);
}
    : function (arg, view) {
    const buf = cachedTextEncoder.encode(arg);
    view.set(buf);
    return {
        read: arg.length,
        written: buf.length
    };
});

function passStringToWasm0(arg, malloc, realloc) {

    if (realloc === undefined) {
        const buf = cachedTextEncoder.encode(arg);
        const ptr = malloc(buf.length);
        getUint8Memory0().subarray(ptr, ptr + buf.length).set(buf);
        WASM_VECTOR_LEN = buf.length;
        return ptr;
    }

    let len = arg.length;
    let ptr = malloc(len);

    const mem = getUint8Memory0();

    let offset = 0;

    for (; offset < len; offset++) {
        const code = arg.charCodeAt(offset);
        if (code > 0x7F) break;
        mem[ptr + offset] = code;
    }

    if (offset !== len) {
        if (offset !== 0) {
            arg = arg.slice(offset);
        }
        ptr = realloc(ptr, len, len = offset + arg.length * 3);
        const view = getUint8Memory0().subarray(ptr + offset, ptr + len);
        const ret = encodeString(arg, view);

        offset += ret.written;
    }

    WASM_VECTOR_LEN = offset;
    return ptr;
}

let cachegetInt32Memory0 = null;
function getInt32Memory0() {
    if (cachegetInt32Memory0 === null || cachegetInt32Memory0.buffer !== wasm.memory.buffer) {
        cachegetInt32Memory0 = new Int32Array(wasm.memory.buffer);
    }
    return cachegetInt32Memory0;
}
/**
*/
export class CoordCelestial {

    static __wrap(ptr) {
        const obj = Object.create(CoordCelestial.prototype);
        obj.ptr = ptr;

        return obj;
    }

    toJSON() {
        return {
            ra: this.ra,
            dec: this.dec,
        };
    }

    toString() {
        return JSON.stringify(this);
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_coordcelestial_free(ptr);
    }
    /**
    */
    get ra() {
        var ret = wasm.__wbg_get_coordcelestial_ra(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set ra(arg0) {
        wasm.__wbg_set_coordcelestial_ra(this.ptr, arg0);
    }
    /**
    */
    get dec() {
        var ret = wasm.__wbg_get_coordcelestial_dec(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set dec(arg0) {
        wasm.__wbg_set_coordcelestial_dec(this.ptr, arg0);
    }
    /**
    * @param {number} ra
    * @param {number} dec
    */
    constructor(ra, dec) {
        var ret = wasm.coordcelestial_new(ra, dec);
        return CoordCelestial.__wrap(ret);
    }
    /**
    * @returns {Point3dJs}
    */
    to_xyz() {
        var ret = wasm.coordcelestial_to_xyz(this.ptr);
        return Point3dJs.__wrap(ret);
    }
}
/**
*/
export class Mtx2x2Js {

    static __wrap(ptr) {
        const obj = Object.create(Mtx2x2Js.prototype);
        obj.ptr = ptr;

        return obj;
    }

    toJSON() {
        return {
        };
    }

    toString() {
        return JSON.stringify(this);
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_mtx2x2js_free(ptr);
    }
    /**
    * @param {number} m11
    * @param {number} m12
    * @param {number} m21
    * @param {number} m22
    */
    constructor(m11, m12, m21, m22) {
        var ret = wasm.mtx2x2js_new(m11, m12, m21, m22);
        return Mtx2x2Js.__wrap(ret);
    }
}
/**
*/
export class Point2dJs {

    static __wrap(ptr) {
        const obj = Object.create(Point2dJs.prototype);
        obj.ptr = ptr;

        return obj;
    }

    toJSON() {
        return {
            x: this.x,
            y: this.y,
        };
    }

    toString() {
        return JSON.stringify(this);
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_point2djs_free(ptr);
    }
    /**
    */
    get x() {
        var ret = wasm.__wbg_get_coordcelestial_ra(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set x(arg0) {
        wasm.__wbg_set_coordcelestial_ra(this.ptr, arg0);
    }
    /**
    */
    get y() {
        var ret = wasm.__wbg_get_coordcelestial_dec(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set y(arg0) {
        wasm.__wbg_set_coordcelestial_dec(this.ptr, arg0);
    }
    /**
    * @param {number} x
    * @param {number} y
    */
    constructor(x, y) {
        var ret = wasm.coordcelestial_new(x, y);
        return Point2dJs.__wrap(ret);
    }
}
/**
*/
export class Point3dJs {

    static __wrap(ptr) {
        const obj = Object.create(Point3dJs.prototype);
        obj.ptr = ptr;

        return obj;
    }

    toJSON() {
        return {
            x: this.x,
            y: this.y,
            z: this.z,
        };
    }

    toString() {
        return JSON.stringify(this);
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_point3djs_free(ptr);
    }
    /**
    */
    get x() {
        var ret = wasm.__wbg_get_coordcelestial_ra(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set x(arg0) {
        wasm.__wbg_set_coordcelestial_ra(this.ptr, arg0);
    }
    /**
    */
    get y() {
        var ret = wasm.__wbg_get_coordcelestial_dec(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set y(arg0) {
        wasm.__wbg_set_coordcelestial_dec(this.ptr, arg0);
    }
    /**
    */
    get z() {
        var ret = wasm.__wbg_get_point3djs_z(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set z(arg0) {
        wasm.__wbg_set_point3djs_z(this.ptr, arg0);
    }
    /**
    * @param {number} x
    * @param {number} y
    * @param {number} z
    */
    constructor(x, y, z) {
        var ret = wasm.point3djs_new(x, y, z);
        return Point3dJs.__wrap(ret);
    }
    /**
    * @returns {CoordCelestial}
    */
    to_celestial() {
        var ret = wasm.point3djs_to_celestial(this.ptr);
        return CoordCelestial.__wrap(ret);
    }
}
/**
*/
export class WcsTan {

    static __wrap(ptr) {
        const obj = Object.create(WcsTan.prototype);
        obj.ptr = ptr;

        return obj;
    }

    toJSON() {
        return {
        };
    }

    toString() {
        return JSON.stringify(this);
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_wcstan_free(ptr);
    }
    /**
    * @param {Point2dJs} crpix
    * @param {CoordCelestial} crval
    * @param {Mtx2x2Js} cd
    */
    constructor(crpix, crval, cd) {
        _assertClass(crpix, Point2dJs);
        var ptr0 = crpix.ptr;
        crpix.ptr = 0;
        _assertClass(crval, CoordCelestial);
        var ptr1 = crval.ptr;
        crval.ptr = 0;
        _assertClass(cd, Mtx2x2Js);
        var ptr2 = cd.ptr;
        cd.ptr = 0;
        var ret = wasm.wcstan_new(ptr0, ptr1, ptr2);
        return WcsTan.__wrap(ret);
    }
    /**
    * @returns {Point2dJs}
    */
    get_crpix() {
        var ret = wasm.wcstan_get_crpix(this.ptr);
        return Point2dJs.__wrap(ret);
    }
    /**
    * @returns {CoordCelestial}
    */
    get_crval() {
        var ret = wasm.wcstan_get_crval(this.ptr);
        return CoordCelestial.__wrap(ret);
    }
    /**
    * @param {Float64Array} new_sip_params_x
    * @param {Float64Array} new_sip_params_y
    * @returns {WcsTan}
    */
    with_updated_sip(new_sip_params_x, new_sip_params_y) {
        var ptr0 = passArrayF64ToWasm0(new_sip_params_x, wasm.__wbindgen_malloc);
        var len0 = WASM_VECTOR_LEN;
        var ptr1 = passArrayF64ToWasm0(new_sip_params_y, wasm.__wbindgen_malloc);
        var len1 = WASM_VECTOR_LEN;
        var ret = wasm.wcstan_with_updated_sip(this.ptr, ptr0, len0, ptr1, len1);
        return WcsTan.__wrap(ret);
    }
    /**
    * @returns {WcsTan}
    */
    with_blank_sip() {
        var ret = wasm.wcstan_with_blank_sip(this.ptr);
        return WcsTan.__wrap(ret);
    }
    /**
    * @param {Point3dJs} world
    * @returns {Point2dJs | undefined}
    */
    world_2_pix(world) {
        _assertClass(world, Point3dJs);
        var ptr0 = world.ptr;
        world.ptr = 0;
        var ret = wasm.wcstan_world_2_pix(this.ptr, ptr0);
        return ret === 0 ? undefined : Point2dJs.__wrap(ret);
    }
    /**
    * @param {Point3dJs} world
    * @returns {Point2dJs | undefined}
    */
    world_2_pix_no_sip(world) {
        _assertClass(world, Point3dJs);
        var ptr0 = world.ptr;
        world.ptr = 0;
        var ret = wasm.wcstan_world_2_pix_no_sip(this.ptr, ptr0);
        return ret === 0 ? undefined : Point2dJs.__wrap(ret);
    }
    /**
    * @param {Point2dJs} pix
    * @returns {Point3dJs}
    */
    pix_2_world(pix) {
        _assertClass(pix, Point2dJs);
        var ptr0 = pix.ptr;
        pix.ptr = 0;
        var ret = wasm.wcstan_pix_2_world(this.ptr, ptr0);
        return Point3dJs.__wrap(ret);
    }
}

async function load(module, imports) {
    if (typeof Response === 'function' && module instanceof Response) {
        if (typeof WebAssembly.instantiateStreaming === 'function') {
            try {
                return await WebAssembly.instantiateStreaming(module, imports);

            } catch (e) {
                if (module.headers.get('Content-Type') != 'application/wasm') {
                    console.warn("`WebAssembly.instantiateStreaming` failed because your server does not serve wasm with `application/wasm` MIME type. Falling back to `WebAssembly.instantiate` which is slower. Original error:\n", e);

                } else {
                    throw e;
                }
            }
        }

        const bytes = await module.arrayBuffer();
        return await WebAssembly.instantiate(bytes, imports);

    } else {
        const instance = await WebAssembly.instantiate(module, imports);

        if (instance instanceof WebAssembly.Instance) {
            return { instance, module };

        } else {
            return instance;
        }
    }
}

async function init(input) {
    if (typeof input === 'undefined') {
        input = new URL('index_bg.wasm', ({ url: getAbsoluteUrl('dist/wcs-wasm/index.js'), env: __SNOWPACK_ENV__ }).url);
    }
    const imports = {};
    imports.wbg = {};
    imports.wbg.__wbg_new_693216e109162396 = function() {
        var ret = new Error();
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_stack_0ddaca5d1abfb52f = function(arg0, arg1) {
        var ret = getObject(arg1).stack;
        var ptr0 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len0 = WASM_VECTOR_LEN;
        getInt32Memory0()[arg0 / 4 + 1] = len0;
        getInt32Memory0()[arg0 / 4 + 0] = ptr0;
    };
    imports.wbg.__wbg_error_09919627ac0992f5 = function(arg0, arg1) {
        try {
            console.error(getStringFromWasm0(arg0, arg1));
        } finally {
            wasm.__wbindgen_free(arg0, arg1);
        }
    };
    imports.wbg.__wbindgen_object_drop_ref = function(arg0) {
        takeObject(arg0);
    };
    imports.wbg.__wbindgen_throw = function(arg0, arg1) {
        throw new Error(getStringFromWasm0(arg0, arg1));
    };

    if (typeof input === 'string' || (typeof Request === 'function' && input instanceof Request) || (typeof URL === 'function' && input instanceof URL)) {
        input = fetch(input);
    }



    const { instance, module } = await load(await input, imports);

    wasm = instance.exports;
    init.__wbindgen_wasm_module = module;

    return wasm;
}

export default init;

