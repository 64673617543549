import React from "../../_snowpack/pkg/react.js";
const steps = [
  {
    content: /* @__PURE__ */ React.createElement("div", null, "Solvastro is a tool for analysing astronomical images, be those from a camera or a telescope, in order to work out exactly where in the sky the image is located - what's known as a ", /* @__PURE__ */ React.createElement("em", null, "Blind Plate Solver"), ". Once this analysis is complete, the image is annotated with stars and constellation names, and you are able to download the calculated WCS, or ", /* @__PURE__ */ React.createElement("em", null, "World Coordinate System"), ", in FITS or JSON format."),
    target: "body",
    title: "Welcome!",
    disableBeacon: true,
    placement: "center",
    hideCloseButton: true
  },
  {
    content: /* @__PURE__ */ React.createElement("div", null, "Let's get started! Normally you would hit this button to import an image of your own, but let's click next to use one of our example images."),
    target: ".walkthrough-target-import-image",
    title: "Get Started!",
    disableOverlayClose: true,
    spotlightClicks: true,
    hideCloseButton: true
  },
  {
    content: /* @__PURE__ */ React.createElement("div", null, "Imported images show in the image tray, along with the progress of their analysis. Click next to perform the first phase of the analysis: extracting points."),
    target: ".walkthrough-target-image-list",
    title: "Image Tray",
    disableBeacon: true,
    disableOverlayClose: true,
    spotlightClicks: true,
    hideCloseButton: true,
    offset: 150
  },
  {
    content: /* @__PURE__ */ React.createElement("div", null, "Now you can see grey circles overlaid onto the image to indicate candidate features in the image that might correspond to stars. The next phase is to submit the positions and relative intensities of these features to our servers in order to try to find a match on the sky."),
    target: ".walkthrough-target-image-list",
    title: "Analysis Phase",
    disableBeacon: true,
    disableOverlayClose: true,
    spotlightClicks: true,
    hideCloseButton: true
  },
  {
    content: /* @__PURE__ */ React.createElement("div", null, "Once the servers solve the image and returns the solution, your image is annotated. Grey circles from the prior step that have been successfully matched to stars are changed to green. The cyan circles represent stars and their names are written beneath. Their position is based on the transformation matrix returned by the server - the closer they all are to their corresponding green circles, the better the solution is. Constellation names are also overlaid, along with a dotted grid at major intervals of ascension and declination."),
    target: ".walkthrough-target-image-list",
    title: "Analysis Complete",
    disableBeacon: true,
    disableOverlayClose: true,
    spotlightClicks: true,
    hideCloseButton: true
  },
  {
    content: /* @__PURE__ */ React.createElement("div", null, "If you're an amateur or professional astronomer and have just came here to get a WCS for your image, click this button to download it. To find out more about how the interactive image explorer works, click the red button to continue."),
    target: ".walkthrough-target-download-wcs",
    title: "Downloading the WCS",
    disableBeacon: true,
    placementBeacon: "top",
    hideCloseButton: true
  },
  {
    content: /* @__PURE__ */ React.createElement("div", null, "The skymap shows where your image is pointing at from the perspective of the whole sky. The red shape represents how the current viewport on your image maps onto the sky. When you zoom in, this will shrink to match the visible zoomed in section, whilst the static white shape represents how your entire zoomed-out image maps onto the sky."),
    target: ".walkthrough-target-skymap",
    title: "Skymap",
    disableBeacon: true,
    hideCloseButton: true
  },
  {
    content: /* @__PURE__ */ React.createElement("div", null, "The minimap shows a thumbnail of your whole image. As with the skymap, if you zoom in, the red rectangle represents where the section of image in the main viewing pane is located with respect to the whole image."),
    target: ".walkthrough-target-minimap",
    title: "Minimap",
    disableBeacon: true,
    hideCloseButton: true
  },
  {
    content: /* @__PURE__ */ React.createElement("div", null, "Use the mousewheel in the main viewing pane to zoom in and out. To move around the image, drag the main image. When you move your mouse around the main viewing pane, you will see two crosshairs that follow the mouse. The red crosshair represents x,y axes in image space, and the green crosshair represents the mapped Right Ascension and Declination axes on the sky."),
    target: "body",
    placement: "center",
    title: "Main View Pane",
    disableBeacon: true,
    hideCloseButton: true
  },
  {
    content: /* @__PURE__ */ React.createElement("div", null, "Solvastro is free to use for up to three times per day. Unlimited access can be had for just \xA32 / $2 / \u20AC2 per month via Patreon. You'll need to register an account on order to make use of the three free credits - this is purely so that we can prevent abuse. The only information that we retain is your email address."),
    target: ".walkthrough-target-query-credits",
    title: "Query Credits",
    disableBeacon: true,
    hideCloseButton: true
  }
];
export default steps;
