import {
  isRejectedWithValue
} from "../../_snowpack/pkg/@reduxjs/toolkit.js";
import {actions as toastActions} from "../reducers/toastSlice.js";
import ToastPatreonLink from "../components/ToastPatreonLink.js";
const {enqueueSnackbar} = toastActions;
export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (!shouldIgnoreError(action)) {
      if (isUnauthenticated(action)) {
        if (action.meta.arg?.endpointName !== "me") {
          api.dispatch(enqueueSnackbar({
            message: "You need to sign in to perform this action.",
            key: new Date().getTime() + Math.random(),
            options: {
              variant: "warning"
            }
          }));
        }
      } else if (isUnauthorized(action)) {
        api.dispatch(enqueueSnackbar({
          message: "You do not have permission to perform this action.",
          key: new Date().getTime() + Math.random(),
          options: {
            variant: "error"
          }
        }));
      } else if (tooManyRequests(action)) {
        api.dispatch(enqueueSnackbar({
          key: new Date().getTime() + Math.random(),
          options: {
            variant: "warning",
            action: ToastPatreonLink
          }
        }));
      } else {
        api.dispatch(enqueueSnackbar({
          message: action.payload?.error ?? action.error?.message,
          key: new Date().getTime() + Math.random(),
          options: {
            variant: "error"
          }
        }));
      }
    }
  }
  return next(action);
};
function isUnauthenticated(action) {
  return action?.payload?.status === 401 || action?.payload?.originalStatus === 401;
}
function isUnauthorized(action) {
  return action?.payload?.status === 403 || action?.payload?.originalStatus === 403;
}
function tooManyRequests(action) {
  return action?.payload?.status === 429 || action?.payload?.originalStatus === 429;
}
function shouldIgnoreError(action) {
  const url = action.meta?.baseQueryMeta?.request?.url ?? null;
  if (url === null) {
    return false;
  }
  const parsed = new URL(url);
  if (parsed.pathname === "/auth/me") {
    return true;
  }
  return false;
}
