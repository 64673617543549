import {createApi, fetchBaseQuery, retry} from "../_snowpack/pkg/@reduxjs/toolkit/query/react.js";
import init from "./wcs-wasm/index.js";
import config from "./config.js";
init();
const bailingBaseQuery = (baseQueryArgs) => retry(async (args, api, extraOptions) => {
  const result = await fetchBaseQuery(baseQueryArgs)(args, api, extraOptions);
  if (result.error) {
    if (typeof result.error.status === "number" && (result.error.status > 400 && result.error.status < 500)) {
      retry.fail(result.error);
    }
    const err = result.error;
    const origStatus = err.originalStatus;
    if (typeof origStatus === "number" && (origStatus >= 400 && origStatus < 500)) {
      retry.fail(result.error);
    }
  }
  return result;
});
export const imageQueryApi = createApi({
  baseQuery: bailingBaseQuery({
    baseUrl: config.apiBaseUrlSolver,
    credentials: "include",
    method: "GET"
  }),
  endpoints: (builder) => ({
    performImageQuery: builder.query({
      query: (imageQuery) => ({
        url: "query",
        method: "POST",
        body: imageQuery,
        credentials: "include",
        extraOptions: {
          maxRetries: 0
        }
      })
    }),
    getRemainingQueryCount: builder.query({
      query: () => "remaining"
    })
  })
});
export const authApi = createApi({
  reducerPath: "auth",
  baseQuery: bailingBaseQuery({
    baseUrl: config.apiBaseUrlAuth,
    credentials: "include",
    method: "GET"
  }),
  endpoints: (builder) => ({
    me: builder.query({query: () => "me"}),
    logout: builder.query({query: () => "logout"})
  })
});
