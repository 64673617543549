import {createSlice} from "../../_snowpack/pkg/@reduxjs/toolkit.js";
const INITIAL_STATE = {
  run: false,
  stepIndex: 0
};
const walkthroughSlice = createSlice({
  name: "walkthrough",
  initialState: INITIAL_STATE,
  reducers: {
    advance: (state, {payload: delta = 1}) => {
      state.stepIndex += delta;
    },
    clickedNext: (state) => {
    },
    run: (state) => {
      state.run = true;
    },
    end: (state) => {
      state.run = false;
    }
  }
});
export default walkthroughSlice;
export const {actions} = walkthroughSlice;
export const rootSelector = (state) => state[walkthroughSlice.name];
