import React from "../../_snowpack/pkg/react.js";
import {useImageTransformContext} from "./ImageTransformContext.js";
import {geoAitoff} from "../../_snowpack/pkg/d3-geo-projection.js";
import * as d3Geo from "../../_snowpack/pkg/d3-geo.js";
import {radians_to_degrees} from "../math-utils.js";
import {Point2dJs} from "../wcs-wasm/index.js";
export function MiniSkyMap({selectedImage}) {
  const {width, height, wcs, corners} = useImageTransformContext();
  const {width: imgWidth, height: imgHeight} = selectedImage;
  const memoizedGraticule = React.useMemo(() => generateGraticule(width, height, wcs, imgWidth, imgHeight), [width, height, wcs, imgWidth, imgHeight]);
  const viewportBorder = React.useMemo(() => generateViewportBorder(corners), [corners]);
  if (!memoizedGraticule || !viewportBorder) {
    return /* @__PURE__ */ React.createElement(React.Fragment, null);
  }
  const {path, graticule, imageBorder} = memoizedGraticule;
  return /* @__PURE__ */ React.createElement("g", {
    className: "walkthrough-target-skymap",
    transform: `
            translate(100, 100)
        `
  }, /* @__PURE__ */ React.createElement("path", {
    d: path(graticule.outline()) || void 0,
    stroke: "grey",
    fillOpacity: 0.5
  }), /* @__PURE__ */ React.createElement("path", {
    d: path(graticule()) || void 0,
    stroke: "grey",
    fillOpacity: 0
  }), /* @__PURE__ */ React.createElement("path", {
    d: path(imageBorder) || void 0,
    fill: "white",
    fillOpacity: 0.2,
    stroke: "white",
    strokeWidth: 2
  }), /* @__PURE__ */ React.createElement("path", {
    d: path(viewportBorder) || void 0,
    fill: "white",
    fillOpacity: 0,
    stroke: "red",
    strokeWidth: 2
  }));
}
function generateViewportBorder(corners) {
  if (!corners) {
    return null;
  }
  return {
    type: "Polygon",
    coordinates: [
      [
        coord_celestial_to_geojson(corners?.tl),
        coord_celestial_to_geojson(corners?.tr),
        coord_celestial_to_geojson(corners?.br),
        coord_celestial_to_geojson(corners?.bl),
        coord_celestial_to_geojson(corners?.tl)
      ]
    ]
  };
}
function generateGraticule(width, height, wcs, imgWidth, imgHeight) {
  if (!wcs) {
    return null;
  }
  const projection = geoAitoff().scale(40).translate([width * 0.075, height * 0.775]).precision(0.1);
  const path = d3Geo.geoPath().projection(projection);
  const graticule = d3Geo.geoGraticule();
  graticule.step([90, 45]);
  const worldTL = wcs.pix_2_world(new Point2dJs(0, 0)).to_celestial();
  const worldTR = wcs.pix_2_world(new Point2dJs(imgWidth, 0)).to_celestial();
  const worldBL = wcs.pix_2_world(new Point2dJs(0, imgHeight)).to_celestial();
  const worldBR = wcs.pix_2_world(new Point2dJs(imgWidth, imgHeight)).to_celestial();
  const imageBorder = {
    type: "Polygon",
    coordinates: [
      [
        coord_celestial_to_geojson(worldTL),
        coord_celestial_to_geojson(worldTR),
        coord_celestial_to_geojson(worldBR),
        coord_celestial_to_geojson(worldBL),
        coord_celestial_to_geojson(worldTL)
      ]
    ]
  };
  return {path, graticule, imageBorder};
}
function coord_celestial_to_geojson(celestial) {
  const lon = radians_to_degrees(-celestial.ra);
  const lat = radians_to_degrees(celestial.dec);
  return [lon, lat];
}
