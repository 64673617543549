import {call, put, take} from "../../_snowpack/pkg/redux-saga/effects.js";
import {actions as walkthroughActions} from "../reducers/walkthroughSlice.js";
import localforage from "../../_snowpack/pkg/localforage.js";
import {ImageCanvas} from "../model/ImageCanvas.js";
import imagesSlice from "../reducers/imagesSlice.js";
import EXAMPLE_IMAGE_1_MATCH_RESULT from "../data/example-image-1-match-result.js";
const EXAMPLE_IMAGE_ID = "EXAMPLE_IMAGE_1";
const CURRENT_WALKTHROUGH_VERSION = "1";
const STEP_EFFECTS = [
  [],
  [
    {star: true, effect: () => importExampleImage()}
  ],
  [
    {star: false, effect: () => put(imagesSlice.actions.imageStarted(EXAMPLE_IMAGE_ID))}
  ],
  [
    {star: false, effect: () => put(imagesSlice.actions.imageQueryResponseFulfilled(EXAMPLE_IMAGE_1_MATCH_RESULT, "EXAMPLE_IMAGE_1"))},
    {star: false, effect: () => new Promise((resolve) => {
      setTimeout(resolve, 200);
    })}
  ],
  [],
  [],
  [],
  [],
  []
];
export function* walkthroughSaga() {
  const latestCompletedWalkthroughVersion = yield call([localStorage, localStorage.getItem], "latestCompletedWalkthroughVersion");
  if (latestCompletedWalkthroughVersion === CURRENT_WALKTHROUGH_VERSION) {
    return;
  }
  yield put(walkthroughActions.run());
  yield take(walkthroughActions.clickedNext().type);
  yield put(walkthroughActions.advance(1));
  yield take(walkthroughActions.clickedNext().type);
  yield importExampleImage();
  yield take(walkthroughActions.advance(1).type);
  yield take(walkthroughActions.clickedNext().type);
  yield put(imagesSlice.actions.imageStarted(EXAMPLE_IMAGE_ID));
  yield take(walkthroughActions.advance(1).type);
  yield take(walkthroughActions.clickedNext().type);
  yield new Promise((resolve) => {
    setTimeout(resolve, 750);
  });
  yield put(imagesSlice.actions.imageQueryResponseFulfilled(EXAMPLE_IMAGE_1_MATCH_RESULT, "EXAMPLE_IMAGE_1"));
  yield put(walkthroughActions.advance(1));
  yield take(walkthroughActions.clickedNext().type);
  yield put(walkthroughActions.advance(1));
  yield take(walkthroughActions.clickedNext().type);
  yield put(walkthroughActions.advance(1));
  yield take(walkthroughActions.clickedNext().type);
  yield put(walkthroughActions.advance(1));
  yield take(walkthroughActions.clickedNext().type);
  yield put(walkthroughActions.advance(1));
  yield take(walkthroughActions.clickedNext().type);
  yield put(walkthroughActions.advance(1));
  yield take(walkthroughActions.clickedNext().type);
  yield put(walkthroughActions.end());
  if (latestCompletedWalkthroughVersion !== "NEVER_SKIP") {
    yield call([localStorage, localStorage.setItem], "latestCompletedWalkthroughVersion", CURRENT_WALKTHROUGH_VERSION);
  }
}
function* importExampleImage() {
  const img = new Image();
  const imgUrl = "/example_images/example1.JPG";
  const imageProm = new Promise((resolve, reject) => {
    img.addEventListener("load", async (ev) => {
      const canvas = new ImageCanvas("#canvas-workbench");
      if (canvas === null || canvas.canvas === null) {
        return;
      }
      canvas.canvas.width = img.width;
      canvas.canvas.height = img.height;
      canvas.context?.drawImage(img, 0, 0);
      const imgData = await localforage.setItem(EXAMPLE_IMAGE_ID, canvas.context?.getImageData(0, 0, img.width, img.height));
      const image2 = {
        id: EXAMPLE_IMAGE_ID,
        fileName: "Example Image",
        fileType: "image/jpeg",
        width: img.width,
        height: img.height,
        imgUrl,
        isLandscape: img.width >= img.height,
        processedId: null,
        extractedPoints: null,
        isWaiting: false,
        imageQueryResponse: null,
        error: null,
        isWalkthrough: true
      };
      resolve(image2);
    });
  });
  img.src = imgUrl;
  const image = yield imageProm;
  yield put(imagesSlice.actions.imageAdded(image));
}
