import React from "../../_snowpack/pkg/react.js";
import {useImageTransformContext} from "./ImageTransformContext.js";
const MINI_MAP_BASE_SCALE_FACTOR = 0.2;
const MINI_MAP_VIEWPORT_STROKE_WIDTH = 4;
const MINI_MAP_INSET_PX = 50;
export function MiniMap({selectedImage}) {
  const {width, height, zoom} = useImageTransformContext();
  const imgAspectRatio = selectedImage.width / selectedImage.height;
  const containerAspectRatio = width / height;
  const imgToContainerWidthRatio = width / selectedImage.width;
  const scaleFactor = MINI_MAP_BASE_SCALE_FACTOR / containerAspectRatio;
  return /* @__PURE__ */ React.createElement("g", {
    className: "walkthrough-target-minimap",
    transform: `
            translate(${calcMiniMapOffset(width, height, selectedImage.width, selectedImage.height)})
        `
  }, /* @__PURE__ */ React.createElement("image", {
    width: width * scaleFactor,
    height: width / imgAspectRatio * scaleFactor,
    href: selectedImage.imgUrl
  }), /* @__PURE__ */ React.createElement("rect", {
    width: width * scaleFactor,
    height: width / imgAspectRatio * scaleFactor,
    stroke: "white",
    fillOpacity: 0
  }), /* @__PURE__ */ React.createElement("rect", {
    width: width * scaleFactor,
    height: height * scaleFactor,
    fill: "white",
    fillOpacity: 0.2,
    stroke: "red",
    strokeWidth: MINI_MAP_VIEWPORT_STROKE_WIDTH * zoom.transformMatrix.scaleX,
    transform: zoomToMiniMapTransform(zoom, imgToContainerWidthRatio, scaleFactor)
  }));
}
function calcMiniMapOffset(width, height, imgWidth, imgHeight) {
  const imgAspectRatio = imgWidth / imgHeight;
  const containerAspectRatio = width / height;
  const scaleFactor = MINI_MAP_BASE_SCALE_FACTOR / containerAspectRatio;
  const indentX = width * scaleFactor + MINI_MAP_VIEWPORT_STROKE_WIDTH * 2 + MINI_MAP_INSET_PX;
  const indentY = width / imgAspectRatio * scaleFactor + MINI_MAP_VIEWPORT_STROKE_WIDTH * 2 + MINI_MAP_INSET_PX;
  let translateX = width - indentX;
  let translateY = height - indentY;
  return `${translateX}, ${translateY}`;
}
function zoomToMiniMapTransform(zoom, imgToContainerWidthRatio, scaleFactor) {
  const inverse = zoom.invert();
  let {scaleX, scaleY, skewX, skewY, translateX, translateY} = inverse;
  scaleX *= imgToContainerWidthRatio;
  scaleY *= imgToContainerWidthRatio;
  translateX *= scaleFactor * imgToContainerWidthRatio;
  translateY *= scaleFactor * imgToContainerWidthRatio;
  return `matrix(${scaleX}, ${skewY}, ${skewX}, ${scaleY}, ${translateX}, ${translateY})`;
}
