function createWorkerMiddleware(worker) {
  return ({dispatch}) => {
    worker.onmessage = async ({data: resultAction}) => {
      dispatch(resultAction);
    };
    return (next) => (action) => {
      if (action.meta && action.meta.WebWorker) {
        const {meta, ...cleanedAction} = action;
        const transferList = meta.transferList;
        worker.postMessage(cleanedAction, transferList);
      }
      return next(action);
    };
  };
}
export default createWorkerMiddleware;
