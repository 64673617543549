import React from "../../_snowpack/pkg/react.js";
import {useImageTransformContext} from "./ImageTransformContext.js";
import {GeoNumber} from "../../_snowpack/pkg/migratory-js.js";
import range from "../../_snowpack/pkg/lodash/range.js";
import {selectedImageSelector} from "../selectors.js";
import {CoordCelestial} from "../wcs-wasm/index.js";
import {degrees_to_radians} from "../math-utils.js";
import {useSelector} from "../../_snowpack/pkg/react-redux.js";
const geoNumber = new GeoNumber(0, true);
export function Graticule() {
  const selectedImage = useSelector(selectedImageSelector);
  const imgWidth = selectedImage?.width || 0;
  const imgHeight = selectedImage?.height || 0;
  const txCtx = useImageTransformContext();
  const {width, height, zoom, wcs: wcsOrUndef, corners, raExtent, decExtent} = txCtx;
  const expandedCroppedProjectedCroppedGratPoints = React.useMemo(() => generateExpandedCroppedProjectedCroppedGratPoints(raExtent, decExtent, wcsOrUndef, imgWidth, imgHeight), [wcsOrUndef, imgWidth, imgHeight, raExtent[0], raExtent[1], decExtent[0], decExtent[1]]);
  const linePaths = React.useMemo(() => generateLinePaths(expandedCroppedProjectedCroppedGratPoints, zoom, width, height, txCtx), [expandedCroppedProjectedCroppedGratPoints, width, height, zoom.transformMatrix]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, linePaths.map((linePath, idx) => /* @__PURE__ */ React.createElement("path", {
    key: idx,
    d: linePath,
    fill: "transparent",
    stroke: "grey",
    strokeWidth: "1",
    strokeDasharray: "5,5",
    opacity: "0.5"
  })));
}
function generateLinePaths(expandedCroppedProjectedCroppedGratPoints, zoom, width, height, txCtx) {
  if (!expandedCroppedProjectedCroppedGratPoints.length) {
    return [];
  }
  const viewportFilteredPoints = filterPointsOutsideViewport(expandedCroppedProjectedCroppedGratPoints, zoom, width, height);
  const expandedViewportFilteredPoints = growUnfilteredRegionViewport(viewportFilteredPoints);
  const linePaths = [];
  const scpBandIndex = expandedViewportFilteredPoints.length - 1;
  const lastPointInBandIndex = expandedViewportFilteredPoints[1].length - 1;
  expandedViewportFilteredPoints.forEach((decBand, decBandIdx) => {
    if (decBandIdx !== 0 && decBandIdx !== scpBandIndex) {
      decBand.forEach((point, idx) => {
        const nextIdx = idx === lastPointInBandIndex ? 0 : idx + 1;
        if (!point.filteredFromViewport && !decBand[nextIdx].filteredFromViewport) {
          linePaths.push(renderLinePath(point, decBand[nextIdx], txCtx));
        }
      });
    }
  });
  expandedViewportFilteredPoints.forEach((decBand, decBandIdx) => {
    if (decBandIdx === 0 && !decBand[0].filteredFromViewport) {
      for (let idx = 0; idx < expandedViewportFilteredPoints[1].length; idx += 6) {
        const point = expandedViewportFilteredPoints[1][idx];
        linePaths.push(renderLinePath(decBand[0], point, txCtx));
      }
    } else if (decBandIdx === scpBandIndex - 1 && !decBand[scpBandIndex].filteredFromViewport) {
      for (let idx = 0; idx < decBand.length; idx += 6) {
        const point = decBand[idx];
        linePaths.push(renderLinePath(point, decBand[scpBandIndex], txCtx));
      }
    } else if (decBandIdx !== scpBandIndex) {
      decBand.forEach((point, pointIdx) => {
        if (!point.filteredFromViewport && !expandedViewportFilteredPoints[decBandIdx + 1][pointIdx].filteredFromViewport) {
          linePaths.push(renderLinePath(point, expandedViewportFilteredPoints[decBandIdx + 1][pointIdx], txCtx));
        }
      });
    }
  });
  return linePaths;
}
function generateExpandedCroppedProjectedCroppedGratPoints(raExtent, decExtent, wcs, imgWidth, imgHeight) {
  if (!wcs) {
    return [];
  }
  const croppedGratPoints = generateMasterGraticulePoints().map((decBand) => decBand.map((point) => {
    point.filteredFromImg = point.raDegrees > raExtent[1] || point.decDegrees < decExtent[0] || point.decDegrees > decExtent[1];
    return point;
  }));
  const projectedCroppedGratPoints = croppedGratPoints.map((decBand) => decBand.map((point) => {
    point.projected = wcs.world_2_pix(point.xyz);
    return point;
  }));
  const croppedProjectedCroppedGratPoints = projectedCroppedGratPoints.map((decBand) => decBand.map((point) => {
    point.filteredFromImg = point.filteredFromImg || !point.projected || point.projected.x < 0 || point.projected.x > imgWidth || point.projected.y < 0 || point.projected.y > imgHeight;
    return point;
  }));
  const expandedCroppedProjectedCroppedGratPoints = growUnfilteredRegionImg(croppedProjectedCroppedGratPoints);
  return expandedCroppedProjectedCroppedGratPoints;
}
function renderLinePath(from, to, txCtx) {
  const numIterations = 5;
  let fromRaRadians = from.raRadians;
  if (from.decDegrees === 90 || from.decDegrees === -90) {
    fromRaRadians = to.raRadians;
  }
  let toRaRadians = to.raRadians;
  if (to.decDegrees === 90 || to.decDegrees === -90) {
    toRaRadians = from.raRadians;
  }
  const startPos = new CoordCelestial(fromRaRadians, from.decRadians);
  let raRange = toRaRadians - fromRaRadians;
  if (raRange > Math.PI) {
    raRange -= 2 * Math.PI;
  } else if (raRange < -Math.PI) {
    raRange += 2 * Math.PI;
  }
  const raStep = raRange / numIterations;
  const decStep = (to.decRadians - from.decRadians) / numIterations;
  const path = pathFromPoint(startPos, txCtx, raStep, decStep, numIterations);
  const start = path.shift();
  if (!start) {
    return "";
  }
  const pathCommands = `M ${Math.round(start.x)} ${Math.round(start.y)}` + path.map((p) => `L ${Math.round(p.x)} ${Math.round(p.y)}`).join(" ");
  return pathCommands;
}
function pathFromPoint(startPos, txCtx, raStep, decStep, iterations) {
  const {wcs, zoom} = txCtx;
  if (!wcs) {
    return [];
  }
  const path = [];
  let pathWorldCoords = new CoordCelestial(startPos.ra, startPos.dec);
  let pathImgCoords = wcs.world_2_pix(pathWorldCoords.to_xyz());
  if (!pathImgCoords) {
    return path;
  }
  let pathViewCoords = zoom.applyToPoint(pathImgCoords);
  path.push(pathViewCoords);
  for (let i = 1; i <= iterations; i++) {
    pathWorldCoords = new CoordCelestial(pathWorldCoords.ra + raStep, pathWorldCoords.dec + decStep);
    pathImgCoords = wcs.world_2_pix(pathWorldCoords.to_xyz());
    if (pathImgCoords) {
      pathViewCoords = zoom.applyToPoint(pathImgCoords);
      path.push(pathViewCoords);
    }
  }
  return path;
}
function generateMasterGraticulePoints() {
  const decBands = range(80, -90, -10).map((decDegrees) => {
    return range(0, 360, 15).map((raDegrees) => createGratPoint(decDegrees, raDegrees));
  });
  decBands.unshift([createGratPoint(90, 0)]);
  decBands.push([createGratPoint(-90, 0)]);
  return decBands;
}
function createGratPoint(decDegrees, raDegrees) {
  const decRadians = degrees_to_radians(decDegrees);
  const raRadians = degrees_to_radians(raDegrees);
  return {
    decDegrees,
    raDegrees,
    decRadians,
    raRadians,
    xyz: new CoordCelestial(raRadians, decRadians).to_xyz(),
    projected: void 0,
    filteredFromImg: false,
    nextFilteredFromImg: false,
    filteredFromViewport: false,
    nextFilteredFromViewport: false
  };
}
function growUnfilteredRegionImg(points) {
  const scpIndex = points.length - 1;
  const lastPointInBandIndex = points[1].length - 1;
  const ncpFiltered = points[0][0].filteredFromImg;
  const scpFiltered = points[scpIndex][0].filteredFromImg;
  for (let decIndex = 1; decIndex <= scpIndex - 1; decIndex++) {
    points[decIndex] = points[decIndex].map((point, raIndex) => {
      if (point.filteredFromImg) {
        if (decIndex === 1 && !ncpFiltered || decIndex > 1 && !points[decIndex - 1][raIndex].filteredFromImg || decIndex < scpIndex - 1 && !points[decIndex + 1][raIndex].filteredFromImg || raIndex === 0 && !points[decIndex][lastPointInBandIndex].filteredFromImg || raIndex === lastPointInBandIndex && !points[decIndex][0].filteredFromImg || raIndex > 0 && !points[decIndex][raIndex - 1].filteredFromImg || raIndex < lastPointInBandIndex && !points[decIndex][raIndex + 1].filteredFromImg) {
          point.nextFilteredFromImg = false;
        } else {
          point.nextFilteredFromImg = true;
        }
      } else {
        point.nextFilteredFromImg = false;
      }
      return point;
    });
  }
  points[0][0].nextFilteredFromImg = ncpFiltered;
  if (ncpFiltered) {
    if (points[1].find((point) => !point.filteredFromImg)) {
      points[0][0].nextFilteredFromImg = false;
    }
  }
  points[scpIndex][0].nextFilteredFromImg = scpFiltered;
  if (scpFiltered) {
    if (points[scpIndex - 1].find((point) => !point.filteredFromImg)) {
      points[scpIndex][0].nextFilteredFromImg = false;
    }
  }
  return points.map((decBand) => decBand.map((point) => {
    point.filteredFromImg = point.nextFilteredFromImg;
    return point;
  }));
}
function growUnfilteredRegionViewport(points) {
  let scpIndex = points.length - 1;
  let finalMeridianIndex = points[1].length - 1;
  let ncpFiltered = points[0][0].filteredFromViewport;
  let scpFiltered = points[scpIndex][0].filteredFromViewport;
  for (let decIndex = 1; decIndex <= scpIndex - 1; decIndex++) {
    points[decIndex] = points[decIndex].map((point, raIndex) => {
      if (point.filteredFromViewport) {
        if (decIndex === 1 && !ncpFiltered || decIndex > 1 && !points[decIndex - 1][raIndex].filteredFromViewport || decIndex < scpIndex - 1 && !points[decIndex + 1][raIndex].filteredFromViewport || raIndex === 0 && !points[decIndex][finalMeridianIndex].filteredFromViewport || raIndex === finalMeridianIndex && !points[decIndex][0].filteredFromViewport || raIndex > 0 && !points[decIndex][raIndex - 1].filteredFromViewport || raIndex < finalMeridianIndex && !points[decIndex][raIndex + 1].filteredFromViewport) {
          point.nextFilteredFromViewport = false;
        } else {
          point.nextFilteredFromViewport = true;
        }
      } else {
        point.nextFilteredFromViewport = false;
      }
      return point;
    });
  }
  points[0][0].nextFilteredFromViewport = ncpFiltered;
  if (ncpFiltered) {
    if (points[1].find((point) => !point.filteredFromViewport)) {
      points[0][0].nextFilteredFromViewport = false;
    }
  }
  points[scpIndex][0].nextFilteredFromViewport = scpFiltered;
  if (scpFiltered) {
    if (points[scpIndex - 1].find((point) => !point.filteredFromViewport)) {
      points[scpIndex][0].nextFilteredFromViewport = false;
    }
  }
  return points.map((decBand) => decBand.map((point) => {
    point.filteredFromViewport = point.nextFilteredFromViewport;
    return point;
  }));
}
function filterPointsOutsideViewport(points, zoom, width, height) {
  return points.map((decBand) => decBand.map((point) => {
    if (!point.filteredFromImg) {
      if (point.projected) {
        const pointPosInViewport = zoom.applyToPoint(point.projected);
        point.filteredFromViewport = !isInRect(pointPosInViewport, 0, width, 0, height);
      } else {
        point.filteredFromViewport = true;
      }
    } else {
      point.filteredFromViewport = true;
    }
    return point;
  }));
}
function isInRect(point, minX, maxX, minY, maxY) {
  return point.x >= minX && point.x <= maxX && point.y >= minY && point.y <= maxY;
}
