import React, {useEffect} from "../../_snowpack/pkg/react.js";
import {useSelector} from "../../_snowpack/pkg/react-redux.js";
import {selectedImageSelector} from "../selectors.js";
import {useImageTransformContext} from "./ImageTransformContext.js";
function ImageMasterCanvas(props) {
  const {width: containerWidth, height: containerHeight, zoom} = useImageTransformContext();
  const selectedImage = useSelector(selectedImageSelector);
  const canvasRef = React.useRef(null);
  const [canvasContext, setCanvasContext] = React.useState(null);
  const [imageObj, setImageObj] = React.useState(null);
  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      setCanvasContext(ctx);
    }
  }, [canvasRef.current]);
  useEffect(() => {
    if (selectedImage?.imgUrl) {
      const img = new Image();
      img.src = selectedImage.imgUrl;
      img.onload = () => {
        setImageObj(img);
      };
    }
  }, [selectedImage?.imgUrl]);
  useEffect(() => {
    if (canvasContext && imageObj && zoom) {
      const destTopLeft = zoom.applyToPoint({x: 0, y: 0});
      const destBottomRight = zoom.applyToPoint({
        x: selectedImage?.width || 1,
        y: selectedImage?.height || 1
      });
      canvasContext.imageSmoothingEnabled = false;
      canvasContext.clearRect(0, 0, containerWidth, containerHeight);
      canvasContext.drawImage(imageObj, 0, 0, selectedImage?.width || 1, selectedImage?.height || 1, destTopLeft.x, destTopLeft.y, destBottomRight.x - destTopLeft.x, destBottomRight.y - destTopLeft.y);
    }
  }, [canvasContext, imageObj, zoom]);
  return /* @__PURE__ */ React.createElement("canvas", {
    width: containerWidth,
    height: containerHeight,
    id: "image-master-canvas",
    ref: canvasRef,
    style: {position: "absolute"}
  });
}
export default ImageMasterCanvas;
