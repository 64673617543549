import React from "../../_snowpack/pkg/react.js";
import {useImageTransformContext} from "./ImageTransformContext.js";
import init, {CoordCelestial, Point2dJs} from "../wcs-wasm/index.js";
import {GeoNumber} from "../../_snowpack/pkg/migratory-js.js";
import {radians_to_degrees, radians_to_hms} from "../math-utils.js";
init();
const TWO_PI = 2 * Math.PI;
const PI_BY_2 = Math.PI / 2;
export function CrossHair() {
  const txCtx = useImageTransformContext();
  const {decExtent, height, raExtent, width, zoom} = txCtx;
  if (!txCtx.wcs || !txCtx.mousePos) {
    return /* @__PURE__ */ React.createElement(React.Fragment, null);
  }
  const mouseInImagePix = zoom.applyInverseToPoint(txCtx.mousePos);
  const mouseLoPix = new Point2dJs(Math.floor(mouseInImagePix.x), Math.floor(mouseInImagePix.y));
  const mouseHiPix = new Point2dJs(Math.ceil(mouseInImagePix.x), Math.ceil(mouseInImagePix.y));
  const mouseLo = zoom.applyToPoint(mouseLoPix);
  const mouseHi = zoom.applyToPoint(mouseHiPix);
  const mouseInWorldCoords = txCtx.wcs.pix_2_world(mouseLoPix).to_celestial();
  const ra = radians_to_hms(mouseInWorldCoords.ra);
  const dec = new GeoNumber(radians_to_degrees(mouseInWorldCoords.dec), true).toDms();
  const raRange = raExtent[1] - raExtent[0];
  const decRange = decExtent[1] - decExtent[0];
  const raPathCommands = buildSvgPathToEdge(mouseInWorldCoords, txCtx, raRange / 2e3, 0);
  const decPathCommands = buildSvgPathToEdge(mouseInWorldCoords, txCtx, 0, decRange / 2e3);
  return /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", {
    x: 0,
    y: mouseLo.y,
    fill: "red",
    width,
    height: Math.max(mouseHi.y - mouseLo.y, 1),
    opacity: "0.2"
  }), /* @__PURE__ */ React.createElement("rect", {
    x: mouseLo.x,
    y: 0,
    fill: "red",
    width: Math.max(mouseHi.x - mouseLo.x, 1),
    height,
    opacity: "0.2"
  }), /* @__PURE__ */ React.createElement("path", {
    d: raPathCommands,
    fill: "transparent",
    stroke: "darkgreen",
    strokeWidth: "1"
  }), /* @__PURE__ */ React.createElement("path", {
    d: decPathCommands,
    fill: "transparent",
    stroke: "darkgreen",
    strokeWidth: "1"
  }), /* @__PURE__ */ React.createElement("rect", {
    x: mouseHi.x + 10,
    y: mouseHi.y + 10,
    rx: 5,
    ry: 5,
    fill: "gray",
    width: "240",
    height: "80",
    opacity: "0.75"
  }), /* @__PURE__ */ React.createElement("text", {
    fontFamily: "monospace",
    x: mouseHi.x + 20,
    y: mouseHi.y + 30
  }, "RA : ", String(ra.h).padStart(2, "0"), "h ", String(ra.m).padStart(2, "0"), "m", " ", String(ra.s.toFixed(4)).padStart(7, "0"), "s"), /* @__PURE__ */ React.createElement("text", {
    fontFamily: "monospace",
    x: mouseHi.x + 20,
    y: mouseHi.y + 45
  }, "Dec: ", String(dec.degree).padStart(2, "0"), "\xB0 ", String(dec.minute).padStart(2, "0"), "\u2032", " ", String(dec.second.toFixed(4)).padStart(7, "0"), "\u2033 ", dec.direction), /* @__PURE__ */ React.createElement("text", {
    fontFamily: "monospace",
    x: mouseHi.x + 20,
    y: mouseHi.y + 60
  }, "Img x: ", Math.floor(mouseInImagePix.x), "px"), /* @__PURE__ */ React.createElement("text", {
    fontFamily: "monospace",
    x: mouseHi.x + 20,
    y: mouseHi.y + 75
  }, "Img y: ", Math.floor(mouseInImagePix.y), "px"));
}
function buildSvgPathToEdge(startPos, txCtx, raStep, decStep) {
  const posPath = pathToEdge(startPos, txCtx, raStep, decStep);
  const negPath = pathToEdge(startPos, txCtx, -raStep, -decStep);
  const path = posPath.reverse().concat(negPath);
  const start = posPath.shift();
  const pathCommands = `M ${Math.round(start.x)} ${Math.round(start.y)}` + path.map((p) => `L ${Math.round(p.x)} ${Math.round(p.y)}`).join(" ");
  return pathCommands;
}
function pathToEdge(startPos, txCtx, raStep, decStep) {
  const {height, wcs, width, zoom} = txCtx;
  if (!wcs) {
    return [];
  }
  const path = [];
  let pathWorldCoords = new CoordCelestial(startPos.ra, startPos.dec);
  let pathImgCoords = wcs.world_2_pix(pathWorldCoords.to_xyz());
  if (!pathImgCoords) {
    return path;
  }
  let pathViewCoords = zoom.applyToPoint(pathImgCoords);
  path.push(pathViewCoords);
  let raTotalDist = 0;
  while (pathViewCoords && pathViewCoords.x >= 0 && pathViewCoords.x < width && pathViewCoords.y >= 0 && pathViewCoords.y < height && raTotalDist < TWO_PI && raTotalDist > -TWO_PI && pathWorldCoords.dec < PI_BY_2 && pathWorldCoords.dec > -PI_BY_2) {
    raTotalDist += raStep;
    pathWorldCoords = new CoordCelestial(pathWorldCoords.ra + raStep, clamp(pathWorldCoords.dec + decStep, -PI_BY_2, PI_BY_2));
    pathImgCoords = wcs.world_2_pix(pathWorldCoords.to_xyz());
    if (pathImgCoords) {
      pathViewCoords = zoom.applyToPoint(pathImgCoords);
      path.push(pathViewCoords);
    }
  }
  return path;
}
function clamp(val, min, max) {
  return Math.max(min, Math.min(max, val));
}
