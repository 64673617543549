import React from "../../_snowpack/pkg/react.js";
import {useSelector} from "../../_snowpack/pkg/react-redux.js";
import {LinePath} from "../../_snowpack/pkg/@visx/shape.js";
import {flatten, some} from "../../_snowpack/pkg/lodash.js";
import {CoordCelestial} from "../wcs-wasm/index.js";
import {selectedImageSelector} from "../selectors.js";
import {degrees_to_radians} from "../math-utils.js";
import {useImageTransformContext} from "./ImageTransformContext.js";
import constellationLinesRaw from "../data/constellation-lines.json";
export function ConstellationLines() {
  const selectedImage = useSelector(selectedImageSelector);
  const imgWidth = selectedImage?.width || 0;
  const imgHeight = selectedImage?.height || 0;
  const txCtx = useImageTransformContext();
  const {width, height, zoom, wcs: wcsOrUndef, corners, raExtent, decExtent} = txCtx;
  const imageCroppedProjectedConstellations = React.useMemo(() => generateImageCroppedConstellations(raExtent, decExtent, wcsOrUndef, imgWidth, imgHeight), [wcsOrUndef, imgWidth, imgHeight, raExtent[0], raExtent[1], decExtent[0], decExtent[1]]);
  const viewportFilteredConstellations = React.useMemo(() => filterLinesOutsideViewport(imageCroppedProjectedConstellations, zoom, width, height), [imageCroppedProjectedConstellations, width, height, zoom.transformMatrix]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, flatten(viewportFilteredConstellations.filter((c) => !c.properties.filteredFromViewport).map((constellation, cIdx) => constellation.properties.viewportPos.map((line, lIdx) => /* @__PURE__ */ React.createElement(LinePath, {
    key: `${cIdx}-${lIdx}`,
    data: line,
    x: (d) => d.x,
    y: (d) => d.y,
    strokeWidth: "2",
    strokeOpacity: "0.25",
    stroke: "white"
  })))));
}
function generateImageCroppedConstellations(raExtent, decExtent, wcs, imgWidth, imgHeight) {
  if (!wcs) {
    return [];
  }
  const constellationLines = constellationLinesRaw.features.map((f) => {
    const cf = {
      ...f,
      type: "Feature",
      geometry: {
        type: "MultiLineString",
        coordinates: f.geometry.coordinates
      }
    };
    cf.properties.xyz = multiStringToXyz(cf.geometry);
    return cf;
  });
  const imageExtentFilteredConstellations = constellationLines.map((constellation) => {
    constellation.properties.filteredFromImg = !some(constellation.geometry.coordinates, (line) => !some(line, isPointWithinExtent(raExtent, decExtent)));
    return constellation;
  });
  const projectedImageFilteredConstellations = imageExtentFilteredConstellations.map((constellation) => {
    constellation.properties.projected = constellation.properties.xyz.map((line) => line.map((point) => wcs.world_2_pix(point)));
    return constellation;
  });
  const imageCroppedProjectedConstellations = projectedImageFilteredConstellations.map((constellation) => {
    constellation.properties.filteredFromImg = constellation.properties.filteredFromImg || !some(constellation.properties.projected, (line) => !some(line, isProjectedWithinImg(imgWidth, imgHeight)));
    return constellation;
  });
  return imageCroppedProjectedConstellations;
}
function multiStringToXyz(feat) {
  return feat.coordinates.map((line) => line.map(([ra, dec]) => new CoordCelestial(degrees_to_radians(ra), degrees_to_radians(dec)).to_xyz()));
}
function filterLinesOutsideViewport(constellations, zoom, width, height) {
  return constellations.map((constellation) => {
    if (!constellation.properties.filteredFromImg) {
      if (constellation.properties.projected) {
        const zoomed = constellation.properties.projected.map((line) => line.map(zoom.applyToPoint));
        const filtered = zoomed.filter((line) => some(line, (point) => isInRect(point, 0, width, 0, height)));
        constellation.properties.viewportPos = filtered;
        constellation.properties.filteredFromViewport = !filtered.length;
      } else {
        constellation.properties.filteredFromViewport = true;
      }
    } else {
      constellation.properties.filteredFromViewport = true;
    }
    return constellation;
  });
}
function isInRect(point, minX, maxX, minY, maxY) {
  return point.x >= minX && point.x <= maxX && point.y >= minY && point.y <= maxY;
}
function isPointWithinExtent(raExtent, decExtent) {
  return function(point) {
    return point[0] <= raExtent[0] || point[0] > raExtent[1] || point[1] < decExtent[0] || point[1] > decExtent[1];
  };
}
function isProjectedWithinImg(imgWidth, imgHeight) {
  return function(point) {
    return point && point[0] >= 0 && point[0] <= imgWidth && point[1] >= 0 && point[1] <= imgHeight;
  };
}
