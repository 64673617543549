import {createEntityAdapter, createSlice} from "../../_snowpack/pkg/@reduxjs/toolkit.js";
const initialState = {
  selectedId: null
};
const imagesAdapter = createEntityAdapter({
  selectId: (image) => image.id
});
const imagesSlice = createSlice({
  name: "images",
  initialState: imagesAdapter.getInitialState(initialState),
  reducers: {
    imageAdded: (state, action) => {
      const newState = imagesAdapter.addOne(state, action);
      newState.selectedId = action.payload.id;
      return newState;
    },
    imageSelected: (state, action) => {
      state.selectedId = action.payload;
      return state;
    },
    imageStarted: (state, action) => {
      if (state.entities[action.payload]) {
        state.entities[action.payload].isWaiting = true;
      }
      return state;
    },
    imagePointsExtracted: {
      reducer(state, action) {
        if (state.entities[action.meta.imageId]) {
          state.entities[action.meta.imageId].extractedPoints = action.payload;
          state.entities[action.meta.imageId].error = null;
          state.entities[action.meta.imageId].imageQueryResponse = null;
          state.entities[action.meta.imageId].isWaiting = false;
        }
        return state;
      },
      prepare(payload, imageId) {
        return {payload, meta: {imageId}};
      }
    },
    imageQueryRequestDispatched: (state, action) => {
      if (state.entities[action.payload]) {
        state.entities[action.payload].isWaiting = true;
        state.entities[action.payload].error = null;
      }
      return state;
    },
    imageQueryResponseFulfilled: {
      reducer(state, action) {
        if (state.entities[action.meta.imageId]) {
          state.entities[action.meta.imageId].imageQueryResponse = action.payload;
          state.entities[action.meta.imageId].error = null;
          state.entities[action.meta.imageId].isWaiting = false;
        }
        return state;
      },
      prepare(payload, imageId) {
        return {payload, meta: {imageId}};
      }
    },
    imageQueryResponseRejected: {
      reducer(state, action) {
        if (state.entities[action.meta.imageId]) {
          state.entities[action.meta.imageId].imageQueryResponse = null;
          state.entities[action.meta.imageId].error = String(action?.payload?.status || "Unknown Error");
          state.entities[action.meta.imageId].isWaiting = false;
        }
        return state;
      },
      prepare(payload, imageId) {
        return {payload, meta: {imageId}};
      }
    }
  }
});
export default imagesSlice;
export const rootSelector = (state) => state.images;
export const selectors = imagesAdapter.getSelectors(rootSelector);
