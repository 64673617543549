import React from "../../_snowpack/pkg/react.js";
import {Box} from "../../_snowpack/pkg/@mui/material.js";
import useNotifier from "../hooks/use-notifier.js";
import Header from "./Header.js";
import ImageList from "./ImageList.js";
import ImageMain from "./ImageMain.js";
import {Walkthrough} from "./Walkthrough.js";
const style = {
  height: "calc(100vh - 72px)",
  minHeight: 500
};
function App({}) {
  useNotifier();
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Header, null), /* @__PURE__ */ React.createElement(Box, {
    sx: {
      height: "calc(100vh - 64px)",
      minHeight: 500,
      bgcolor: "background.default",
      width: "100vw"
    }
  }, /* @__PURE__ */ React.createElement(ImageList, null), /* @__PURE__ */ React.createElement(ImageMain, null)), /* @__PURE__ */ React.createElement(Walkthrough, null));
}
export default App;
