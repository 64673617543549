import React from "../../_snowpack/pkg/react.js";
import {useSelector} from "../../_snowpack/pkg/react-redux.js";
import {Box} from "../../_snowpack/pkg/@mui/material.js";
import {RectClipPath} from "../../_snowpack/pkg/@visx/clip-path.js";
import {Zoom, applyMatrixToPoint} from "../../_snowpack/pkg/@visx/zoom.js";
import {selectedImageSelector} from "../selectors.js";
import ZoomCanvas from "./ZoomCanvas.js";
import {MiniMap} from "./MiniMap.js";
import {MiniSkyMap} from "./MiniSkyMap.js";
import {QueryPointMarker} from "./QueryPointMarker.js";
import {InteractionCaptureSurface} from "./InteractionCaptureSurface.js";
import {deriveImageTransformContext, ImageTransformContext} from "./ImageTransformContext.js";
import {ReferencePointMarkers} from "./ReferencePointMarkers.js";
import {CrossHair} from "./CrossHair.js";
import {Graticule} from "./Graticule.js";
import {ConstellationLabels} from "./ConstellationLabels.js";
import {ConstellationLines} from "./ConstellationLines.js";
import ImageDetails from "./ImageDetails.js";
export default function ImageMaster({width, height}) {
  const selectedImage = useSelector(selectedImageSelector);
  const [showMiniMap, setShowMiniMap] = React.useState(true);
  const [showMiniSkyMap, setShowMiniSkyMap] = React.useState(true);
  const [mousePos, rawSetMousePos] = React.useState(null);
  const setMousePos = (evt) => {
    const newMousePos = evt ? {x: evt.nativeEvent.offsetX, y: evt.nativeEvent.offsetY} : null;
    rawSetMousePos(newMousePos);
  };
  if (!selectedImage || width === null || height === null) {
    return /* @__PURE__ */ React.createElement("div", null);
  }
  const extractedPoints = selectedImage.extractedPoints ?? [];
  const solutionMatchedPoints = selectedImage.imageQueryResponse?.matches ?? [];
  const solution = selectedImage.imageQueryResponse?.solution;
  const {constrain, initialTransform} = createConstrainFunction(width, height, selectedImage);
  return /* @__PURE__ */ React.createElement("div", {
    id: "image-master-container",
    className: "walkthrough-target-main-view-pane"
  }, /* @__PURE__ */ React.createElement(Zoom, {
    width,
    height,
    constrain,
    transformMatrix: initialTransform
  }, (zoom) => /* @__PURE__ */ React.createElement(ImageTransformContext.Provider, {
    value: deriveImageTransformContext({
      width,
      height,
      solution,
      zoom,
      mousePos,
      setMousePos
    })
  }, /* @__PURE__ */ React.createElement("div", {
    style: {position: "relative"}
  }, /* @__PURE__ */ React.createElement(ZoomCanvas, null), /* @__PURE__ */ React.createElement("svg", {
    width,
    height,
    style: {
      position: "absolute",
      cursor: zoom.isDragging ? "grabbing" : "grab"
    }
  }, /* @__PURE__ */ React.createElement(Graticule, null), /* @__PURE__ */ React.createElement(ConstellationLabels, null), /* @__PURE__ */ React.createElement(ConstellationLines, null), /* @__PURE__ */ React.createElement(QueryPoints, {
    extractedPoints,
    zoom,
    solutionMatchedPoints
  }), /* @__PURE__ */ React.createElement(ReferencePointMarkers, null), /* @__PURE__ */ React.createElement(CrossHair, null), /* @__PURE__ */ React.createElement(RectClipPath, {
    id: "zoom-clip",
    width,
    height
  }), /* @__PURE__ */ React.createElement(InteractionCaptureSurface, null), showMiniMap && /* @__PURE__ */ React.createElement(MiniMap, {
    selectedImage
  }), showMiniSkyMap && /* @__PURE__ */ React.createElement(MiniSkyMap, {
    selectedImage
  }))), /* @__PURE__ */ React.createElement(Box, {
    id: "image-detail-wrapper",
    sx: {
      position: "absolute",
      bottom: 0,
      m: 1
    }
  }, /* @__PURE__ */ React.createElement(ImageDetails, null)))));
}
function QueryPoints({extractedPoints, zoom, solutionMatchedPoints}) {
  const zoomedPoints = React.useMemo(() => extractedPoints.map((p) => zoom.applyToPoint(p)), [
    extractedPoints.length,
    zoom.transformMatrix
  ]);
  const zoomedQueryPoints = React.useMemo(() => solutionMatchedPoints && solutionMatchedPoints.map((p) => applyZoomToQueryPointResult(p, zoom)), [solutionMatchedPoints.length, zoom.transformMatrix]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, zoomedPoints.map((point, i) => /* @__PURE__ */ React.createElement(QueryPointMarker, {
    key: i,
    position: point,
    index: i,
    solPoint: zoomedQueryPoints && zoomedQueryPoints[i]
  })));
}
function applyZoomToQueryPointResult(point, zoom) {
  if (point && point !== "Distractor" && point !== "Conflict") {
    const zoomed = zoom.applyToPoint({
      x: point.Match.index_x,
      y: point.Match.index_y
    });
    return {
      ...point,
      Match: {
        ...point.Match,
        index_x: zoomed.x,
        index_y: zoomed.y
      }
    };
  }
  return point;
}
function createConstrainFunction(width, height, selectedImage) {
  const initialTransform = {
    scaleX: 1,
    scaleY: 1,
    translateX: 0,
    translateY: 0,
    skewX: 0,
    skewY: 0
  };
  const imgWidth = selectedImage.width;
  const imgHeight = selectedImage.height;
  const scaleXLimit = width / imgWidth;
  const scaleYLimit = height / imgHeight;
  const scale = Math.min(scaleXLimit, scaleYLimit);
  initialTransform.scaleX = scale;
  initialTransform.scaleY = scale;
  function constrain(transformMatrix, prevTransformMatrix) {
    const topLeft = applyMatrixToPoint(transformMatrix, {x: 0, y: 0});
    const bottomRight = applyMatrixToPoint(transformMatrix, {
      x: selectedImage?.width || width,
      y: selectedImage?.height || height
    });
    if (bottomRight.x < width) {
      transformMatrix.translateX += width - bottomRight.x;
    }
    if (bottomRight.y < height) {
      transformMatrix.translateY += height - bottomRight.y;
    }
    if (topLeft.x > 0) {
      transformMatrix.translateX -= topLeft.x;
    }
    if (topLeft.y > 0) {
      transformMatrix.translateY -= topLeft.y;
    }
    if (transformMatrix.scaleX < scaleXLimit) {
      transformMatrix.scaleX = scaleXLimit;
    }
    if (transformMatrix.scaleY < scaleYLimit) {
      transformMatrix.scaleY = scaleYLimit;
    }
    const scale2 = Math.min(transformMatrix.scaleX, transformMatrix.scaleY);
    transformMatrix.scaleX = scale2;
    transformMatrix.scaleY = scale2;
    const topLeftUpdated = applyMatrixToPoint(transformMatrix, {
      x: 0,
      y: 0
    });
    const bottomRightUpdated = applyMatrixToPoint(transformMatrix, {
      x: selectedImage?.width || width,
      y: selectedImage?.height || height
    });
    if (bottomRightUpdated.x < width) {
      transformMatrix.translateX += (width - bottomRightUpdated.x) / 2;
    }
    if (topLeftUpdated.x > 0) {
      transformMatrix.translateX -= topLeftUpdated.x / 2;
    }
    if (bottomRightUpdated.y < height) {
      transformMatrix.translateY += (height - bottomRightUpdated.y) / 2;
    }
    if (topLeftUpdated.y > 0) {
      transformMatrix.translateY -= topLeftUpdated.y / 2;
    }
    return transformMatrix;
  }
  return {constrain, initialTransform: constrain(initialTransform, initialTransform)};
}
