const res = {
  solution: {
    query_quad: {
      a: 82,
      b: 23,
      c: 76,
      d: 78
    },
    cat_quad_idx: 7175475,
    cat_quad_identifiers: [
      "Betelgeuse",
      "123Zet Tau",
      "39Lam Ori",
      "37Phi1Ori"
    ],
    code_error: 0.016953446915047625,
    wcs: {
      crpix: [
        3400.2657004675766,
        2173.773080848005
      ],
      crval: {
        ra: 1.549729118,
        dec: 0.129277632
      },
      cd: [
        -0.011799871528516092,
        0.0043249372247242766,
        -0.00432950877465609,
        -0.01184958208157113
      ],
      cd_inv: [
        -74.73794809368144,
        -27.278340416121733,
        27.307174197698128,
        -74.42441258598504
      ],
      mtx_w2i: [
        0.002715738077396291,
        -0.9997780945642856,
        0.02088986347733088,
        0.12888922870876632,
        0.02106565046246003,
        0.991435224860128,
        -0.9916552785567948,
        0,
        0.1289178362773968
      ],
      mtx_i2w: [
        0.002715738077396291,
        0.12888922870876632,
        -0.9916552785567948,
        -0.9997780945642856,
        0.02106565046246003,
        0,
        0.02088986347733088,
        0.991435224860128,
        0.1289178362773968
      ],
      sip_params: null
    }
  },
  matches: [
    {
      Match: {
        field: 0,
        star_idx: 955,
        logodds: 3.499465629561554,
        field_x: 4809,
        field_y: 314,
        index_x: 4820.911788243048,
        index_y: 301.5218812542191,
        index_name: "16    Aur"
      }
    },
    {
      Match: {
        field: 1,
        star_idx: 737,
        logodds: 3.164803532154499,
        field_x: 3681,
        field_y: 324,
        index_x: 3682.8674545920903,
        index_y: 304.9988297208506,
        index_name: "44Kap Aur"
      }
    },
    "Distractor",
    {
      Match: {
        field: 3,
        star_idx: 112,
        logodds: 3.7786719218429337,
        field_x: 5202,
        field_y: 422,
        index_x: 5214.347699063928,
        index_y: 412.5509802843235,
        index_name: "Hassaleh"
      }
    },
    {
      Match: {
        field: 4,
        star_idx: 188,
        logodds: 2.315495693613695,
        field_x: 3044,
        field_y: 471,
        index_x: 3037.5229944818425,
        index_y: 448.8085119775714,
        index_name: "27Eps Gem"
      }
    },
    {
      Match: {
        field: 5,
        star_idx: 980,
        logodds: 4.567307982944989,
        field_x: 4006,
        field_y: 625,
        index_x: 4008.644330128799,
        index_y: 616.2235224872468,
        index_name: "136    Tau"
      }
    },
    {
      Match: {
        field: 6,
        star_idx: 525,
        logodds: 1.0831797681123092,
        field_x: 2533,
        field_y: 665,
        index_x: 2517.200625877153,
        index_y: 641.9499730884238,
        index_name: "43Zet Gem"
      }
    },
    {
      Match: {
        field: 7,
        star_idx: 28,
        logodds: 4.672573852430075,
        field_x: 4519,
        field_y: 696,
        index_x: 4523.741519456524,
        index_y: 689.6372922674452,
        index_name: "Alnath"
      }
    },
    {
      Match: {
        field: 8,
        star_idx: 1298,
        logodds: 4.697243829190958,
        field_x: 3873,
        field_y: 732,
        index_x: 3875.225221899406,
        index_y: 724.7143623964841,
        index_name: "139    Tau"
      }
    },
    {
      Match: {
        field: 9,
        star_idx: 150,
        logodds: 4.520518493338514,
        field_x: 3330,
        field_y: 838,
        index_x: 3327.92893527287,
        index_y: 828.5613776437808,
        index_name: "13Mu  Gem"
      }
    },
    "Distractor",
    "Distractor",
    {
      Match: {
        field: 12,
        star_idx: 235,
        logodds: 4.754482258666215,
        field_x: 3473,
        field_y: 895,
        index_x: 3471.4971277789173,
        index_y: 888.3422223063133,
        index_name: "7Eta Gem"
      }
    },
    {
      Match: {
        field: 13,
        star_idx: 1403,
        logodds: 4.913711908650397,
        field_x: 3992,
        field_y: 900,
        index_x: 3993.13788618392,
        index_y: 896.3333026553876,
        index_name: "132    Tau"
      }
    },
    {
      Match: {
        field: 14,
        star_idx: 616,
        logodds: 4.807660729116064,
        field_x: 3685,
        field_y: 909,
        index_x: 3685.3717623580937,
        index_y: 903.0157756425672,
        index_name: "1    Gem"
      }
    },
    {
      Match: {
        field: 15,
        star_idx: 600,
        logodds: 4.53311371121829,
        field_x: 3154,
        field_y: 974,
        index_x: 3149.9846598858735,
        index_y: 965.3550645997154,
        index_name: "18Nu  Gem"
      }
    },
    "Distractor",
    {
      Match: {
        field: 17,
        star_idx: 1059,
        logodds: 4.963636700503843,
        field_x: 3597,
        field_y: 1154,
        index_x: 3596.7503979566113,
        index_y: 1151.8337813535677,
        index_name: "62Chi2Ori"
      }
    },
    {
      Match: {
        field: 18,
        star_idx: 2050,
        logodds: 4.887950759996073,
        field_x: 3374,
        field_y: 1156,
        index_x: 3372.020358312113,
        index_y: 1152.0034318324638,
        index_name: "71    Ori"
      }
    },
    {
      Match: {
        field: 19,
        star_idx: 1903,
        logodds: 4.961436210533005,
        field_x: 3593,
        field_y: 1192,
        index_x: 3592.055491635184,
        index_y: 1189.9256827543,
        index_name: "64    Ori"
      }
    },
    {
      Match: {
        field: 20,
        star_idx: 44,
        logodds: 4.519815703224998,
        field_x: 2881,
        field_y: 1201,
        index_x: 2874.963779388843,
        index_y: 1193.4447700545786,
        index_name: "Alhena"
      }
    },
    {
      Match: {
        field: 21,
        star_idx: 804,
        logodds: 4.979230415918003,
        field_x: 3772,
        field_y: 1206,
        index_x: 3771.8970741265266,
        index_y: 1204.72502121504,
        index_name: "54Chi1Ori"
      }
    },
    {
      Match: {
        field: 22,
        star_idx: 1402,
        logodds: 4.987159793161712,
        field_x: 4305,
        field_y: 1238,
        index_x: 4305.204334400504,
        index_y: 1237.907315296957,
        index_name: "114    Tau"
      }
    },
    {
      Match: {
        field: 23,
        star_idx: 168,
        logodds: 4.985187404450096,
        field_x: 4100,
        field_y: 1242,
        index_x: 4099.333154612667,
        index_y: 1241.9888417241298,
        index_name: "123Zet Tau"
      }
    },
    {
      Match: {
        field: 24,
        star_idx: 1544,
        logodds: 4.981513624932928,
        field_x: 4464,
        field_y: 1272,
        index_x: 4464.957082288864,
        index_y: 1272.5133534198096,
        index_name: "109    Tau"
      }
    },
    {
      Match: {
        field: 25,
        star_idx: 1171,
        logodds: 3.6671828175654184,
        field_x: 2472,
        field_y: 1314,
        index_x: 2459.1203024457755,
        index_y: 1304.0923249831692,
        index_name: "38    Gem"
      }
    },
    {
      Match: {
        field: 26,
        star_idx: 1597,
        logodds: -0.3295246758033912,
        field_x: 1691,
        field_y: 1360,
        index_x: 1713.3167720226472,
        index_y: 1336.219629011303,
        index_name: "2Eps CMi"
      }
    },
    {
      Match: {
        field: 27,
        star_idx: 693,
        logodds: 4.963141421226053,
        field_x: 5210,
        field_y: 1390,
        index_x: 5211.992179062945,
        index_y: 1390.940786699523,
        index_name: "94Tau Tau"
      }
    },
    {
      Match: {
        field: 28,
        star_idx: 902,
        logodds: 4.466235165687399,
        field_x: 2670,
        field_y: 1393,
        index_x: 2662.2672828864816,
        index_y: 1386.3337680645104,
        index_name: "30    Gem"
      }
    },
    {
      Match: {
        field: 29,
        star_idx: 1032,
        logodds: 4.97269465453925,
        field_x: 4758,
        field_y: 1400,
        index_x: 4759.043847948238,
        index_y: 1401.3614898701044,
        index_name: "102Iot Tau"
      }
    },
    {
      Match: {
        field: 30,
        star_idx: 248,
        logodds: 4.164143653402933,
        field_x: 2638,
        field_y: 1409,
        index_x: 2627.7214013469,
        index_y: 1401.318659851309,
        index_name: "31Xi  Gem"
      }
    },
    "Distractor",
    {
      Match: {
        field: 32,
        star_idx: 736,
        logodds: 1.7788788608599084,
        field_x: 4103,
        field_y: 1471,
        index_x: 4127.941920450077,
        index_y: 1475.4334680096715,
        index_name: "119    Tau"
      }
    },
    {
      Match: {
        field: 33,
        star_idx: 699,
        logodds: 4.9486294535197075,
        field_x: 5542,
        field_y: 1475,
        index_x: 5544.730364665073,
        index_y: 1475.5488773170905,
        index_name: "69Ups Tau"
      }
    },
    {
      Match: {
        field: 34,
        star_idx: 337,
        logodds: 4.6215065065278935,
        field_x: 6446,
        field_y: 1508,
        index_x: 6454.507535685665,
        index_y: 1507.1048739805185,
        index_name: "27    Tau"
      }
    },
    {
      Match: {
        field: 35,
        star_idx: 636,
        logodds: 4.930287777126763,
        field_x: 5548,
        field_y: 1524,
        index_x: 5551.20679154389,
        index_y: 1525.0681138941443,
        index_name: "65Kap1Tau"
      }
    },
    {
      Match: {
        field: 36,
        star_idx: 828,
        logodds: 4.973447600923556,
        field_x: 3378,
        field_y: 1540,
        index_x: 3376.861895164041,
        index_y: 1538.776314453601,
        index_name: "67Nu  Ori"
      }
    },
    "Distractor",
    {
      Match: {
        field: 38,
        star_idx: 860,
        logodds: 4.966029250698265,
        field_x: 3283,
        field_y: 1552,
        index_x: 3281.497568410695,
        index_y: 1550.57908152223,
        index_name: "70Xi  Ori"
      }
    },
    "Distractor",
    "Distractor",
    {
      Match: {
        field: 41,
        star_idx: 1348,
        logodds: 4.974449907330808,
        field_x: 3906,
        field_y: 1578,
        index_x: 3904.465663331803,
        index_y: 1578.4878988794449,
        index_name: "126    Tau"
      }
    },
    {
      Match: {
        field: 42,
        star_idx: 2093,
        logodds: 4.756527848825407,
        field_x: 2845,
        field_y: 1611,
        index_x: 2839.485090276358,
        index_y: 1607.02995101456,
        index_name: "HR2375"
      }
    },
    {
      Match: {
        field: 43,
        star_idx: 1449,
        logodds: 4.959177524577905,
        field_x: 4597,
        field_y: 1614,
        index_x: 4595.24518879298,
        index_y: 1615.6022634879532,
        index_name: "104    Tau"
      }
    },
    {
      Match: {
        field: 44,
        star_idx: 1616,
        logodds: 4.972388188249624,
        field_x: 4240,
        field_y: 1615,
        index_x: 4239.495097694465,
        index_y: 1616.6582032716433,
        index_name: "111    Tau"
      }
    },
    {
      Match: {
        field: 45,
        star_idx: 783,
        logodds: 4.816662109801541,
        field_x: 6011,
        field_y: 1633,
        index_x: 6016.826062567437,
        index_y: 1632.5462299601986,
        index_name: "37    Tau"
      }
    },
    {
      Match: {
        field: 46,
        star_idx: 1083,
        logodds: 4.628571511080846,
        field_x: 2626,
        field_y: 1666,
        index_x: 2618.698002354268,
        index_y: 1661.7049730133956,
        index_name: "15    Mon"
      }
    },
    {
      Match: {
        field: 47,
        star_idx: 1696,
        logodds: 4.9530583701142294,
        field_x: 3146,
        field_y: 1666,
        index_x: 3143.999759675406,
        index_y: 1664.3060575765223,
        index_name: "74    Ori"
      }
    },
    {
      Match: {
        field: 48,
        star_idx: 1775,
        logodds: 4.981603765600315,
        field_x: 4917,
        field_y: 1687,
        index_x: 4916.239963037726,
        index_y: 1687.7641037452504,
        index_name: "97    Tau"
      }
    },
    "Distractor",
    "Distractor",
    {
      Match: {
        field: 51,
        star_idx: 1484,
        logodds: 4.921174447488587,
        field_x: 5682,
        field_y: 1711,
        index_x: 5685.621900820017,
        index_y: 1711.3589219901858,
        index_name: "50Ome2Tau"
      }
    },
    "Distractor",
    "Distractor",
    {
      Match: {
        field: 54,
        star_idx: 1225,
        logodds: 4.367463312100506,
        field_x: 2452,
        field_y: 1757,
        index_x: 2441.615248872901,
        index_y: 1752.9819193255594,
        index_name: "17    Mon"
      }
    },
    {
      Match: {
        field: 55,
        star_idx: 300,
        logodds: 4.98295168024541,
        field_x: 5398,
        field_y: 1776,
        index_x: 5398.2524349563255,
        index_y: 1776.910062367102,
        index_name: "74Eps Tau"
      }
    },
    {
      Match: {
        field: 56,
        star_idx: 1420,
        logodds: 4.965589493167965,
        field_x: 3649,
        field_y: 1820,
        index_x: 3646.935392675477,
        index_y: 1819.6811658380348,
        index_name: "134    Tau"
      }
    },
    "Distractor",
    {
      Match: {
        field: 58,
        star_idx: 1295,
        logodds: 4.96036641419183,
        field_x: 4473,
        field_y: 1842,
        index_x: 4470.934317827081,
        index_y: 1843.068549288534,
        index_name: "15    Ori"
      }
    },
    {
      Match: {
        field: 59,
        star_idx: 1072,
        logodds: 4.946446598335241,
        field_x: 4568,
        field_y: 1888,
        index_x: 4565.1490391973175,
        index_y: 1888.2544352636892,
        index_name: "11    Ori"
      }
    },
    {
      Match: {
        field: 60,
        star_idx: 720,
        logodds: 4.984371309299904,
        field_x: 5433,
        field_y: 1898,
        index_x: 5433.577346687897,
        index_y: 1898.5241102494924,
        index_name: "68Del3Tau"
      }
    },
    {
      Match: {
        field: 61,
        star_idx: 881,
        logodds: 4.7418562372854165,
        field_x: 2702,
        field_y: 1917,
        index_x: 2695.4905732604407,
        index_y: 1914.404397668837,
        index_name: "13    Mon"
      }
    },
    {
      Match: {
        field: 62,
        star_idx: 393,
        logodds: 4.98673731225343,
        field_x: 5480,
        field_y: 1944,
        index_x: 5479.762528246847,
        index_y: 1944.2800775975536,
        index_name: "61Del1Tau"
      }
    },
    {
      Match: {
        field: 63,
        star_idx: 1274,
        logodds: 4.985190110532487,
        field_x: 5452,
        field_y: 1946,
        index_x: 5451.621518562952,
        index_y: 1946.5486509650932,
        index_name: "64Del2Tau"
      }
    },
    {
      Match: {
        field: 64,
        star_idx: 13,
        logodds: 4.981086780982922,
        field_x: 5174,
        field_y: 1961,
        index_x: 5172.907655779639,
        index_y: 1961.267746614976,
        index_name: "Aldebaran"
      }
    },
    {
      Match: {
        field: 65,
        star_idx: 594,
        logodds: 4.95472106856511,
        field_x: 3331,
        field_y: 1959,
        index_x: 3328.616215025272,
        index_y: 1958.0750987019096,
        index_name: "61Mu  Ori"
      }
    },
    "Distractor",
    {
      Match: {
        field: 67,
        star_idx: 1099,
        logodds: 4.917959915435246,
        field_x: 5091,
        field_y: 1991,
        index_x: 5087.277123707065,
        index_y: 1991.1734071724995,
        index_name: "92Sig2Tau"
      }
    },
    {
      Match: {
        field: 68,
        star_idx: 1543,
        logodds: 4.964240828182987,
        field_x: 5331,
        field_y: 2014,
        index_x: 5328.863466918669,
        index_y: 2014.26307641748,
        index_name: "75    Tau"
      }
    },
    {
      Match: {
        field: 69,
        star_idx: 1241,
        logodds: 4.986160370339448,
        field_x: 5279,
        field_y: 2016,
        index_x: 5278.850746816872,
        index_y: 2016.4774350460696,
        index_name: "HR1427"
      }
    },
    {
      Match: {
        field: 70,
        star_idx: 428,
        logodds: 4.960103083644675,
        field_x: 5318,
        field_y: 2047,
        index_x: 5315.708389762606,
        index_y: 2046.541712593984,
        index_name: "77The1Tau"
      }
    },
    {
      Match: {
        field: 71,
        star_idx: 1143,
        logodds: 4.906077767536102,
        field_x: 4777,
        field_y: 2050,
        index_x: 4773.004900636375,
        index_y: 2049.4473522526787,
        index_name: "4Omi1Ori"
      }
    },
    {
      Match: {
        field: 72,
        star_idx: 555,
        logodds: 4.917110612863647,
        field_x: 4681,
        field_y: 2085,
        index_x: 4677.251285398843,
        index_y: 2084.917100706385,
        index_name: "9Omi2Ori"
      }
    },
    {
      Match: {
        field: 73,
        star_idx: 893,
        logodds: 4.984559226743961,
        field_x: 5356,
        field_y: 2087,
        index_x: 5355.339949832216,
        index_y: 2087.3671134939123,
        index_name: "71    Tau"
      }
    },
    "Conflict",
    {
      Match: {
        field: 75,
        star_idx: 1071,
        logodds: 4.981286886475841,
        field_x: 5175,
        field_y: 2109,
        index_x: 5173.912127477267,
        index_y: 2109.203513571535,
        index_name: "86Rho Tau"
      }
    },
    {
      Match: {
        field: 76,
        star_idx: 254,
        logodds: 4.9731262233159725,
        field_x: 3842,
        field_y: 2118,
        index_x: 3840.318482785016,
        index_y: 2117.8283545110116,
        index_name: "39Lam Ori"
      }
    },
    {
      Match: {
        field: 77,
        star_idx: 343,
        logodds: 4.985184402699938,
        field_x: 5501,
        field_y: 2123,
        index_x: 5500.432696914493,
        index_y: 2122.648467901209,
        index_name: "54Gam Tau"
      }
    },
    {
      Match: {
        field: 78,
        star_idx: 803,
        logodds: 4.972429197561777,
        field_x: 3836,
        field_y: 2154,
        index_x: 3834.418780333878,
        index_y: 2153.295651218843,
        index_name: "37Phi1Ori"
      }
    },
    {
      Match: {
        field: 79,
        star_idx: 576,
        logodds: 4.961487346164162,
        field_x: 3792,
        field_y: 2156,
        index_x: 3790.190727839608,
        index_y: 2154.617540039554,
        index_name: "40Phi2Ori"
      }
    },
    {
      Match: {
        field: 80,
        star_idx: 1122,
        logodds: 4.976438969286166,
        field_x: 5328,
        field_y: 2161,
        index_x: 5326.51892811902,
        index_y: 2160.9706519513356,
        index_name: "73Pi  Tau"
      }
    },
    "Distractor",
    {
      Match: {
        field: 82,
        star_idx: 9,
        logodds: 4.986801050702745,
        field_x: 3400,
        field_y: 2174,
        index_x: 3400.2657004675766,
        index_y: 2173.773080848005,
        index_name: "Betelgeuse"
      }
    },
    {
      Match: {
        field: 83,
        star_idx: 894,
        logodds: 4.1792397500627985,
        field_x: 2273,
        field_y: 2176,
        index_x: 2260.345254960156,
        index_y: 2174.7790494863534,
        index_name: "18    Mon"
      }
    },
    {
      Match: {
        field: 84,
        star_idx: 805,
        logodds: 4.91702789716088,
        field_x: 2792,
        field_y: 2186,
        index_x: 2788.3603210983247,
        index_y: 2185.0894981600336,
        index_name: "8Eps Mon"
      }
    },
    "Distractor",
    "Distractor",
    {
      Match: {
        field: 87,
        star_idx: 2002,
        logodds: 4.901098759713081,
        field_x: 4659,
        field_y: 2260,
        index_x: 4655.1753093575535,
        index_y: 2258.377115793598,
        index_name: "6    Ori"
      }
    },
    {
      Match: {
        field: 88,
        star_idx: 692,
        logodds: 4.916496142796412,
        field_x: 5027,
        field_y: 2274,
        index_x: 5023.292990779872,
        index_y: 2273.3361427130403,
        index_name: "90    Tau"
      }
    },
    {
      Match: {
        field: 89,
        star_idx: 1656,
        logodds: 4.95671079620104,
        field_x: 5238,
        field_y: 2286,
        index_x: 5235.622158325948,
        index_y: 2285.3029997003073,
        index_name: "79    Tau"
      }
    },
    "Distractor",
    "Distractor",
    {
      Match: {
        field: 92,
        star_idx: 1058,
        logodds: 4.935327345172508,
        field_x: 4623,
        field_y: 2359,
        index_x: 4620.202672915323,
        index_y: 2357.390199517259,
        index_name: "7Pi 1Ori"
      }
    },
    "Distractor",
    {
      Match: {
        field: 94,
        star_idx: 629,
        logodds: 4.964709472354063,
        field_x: 3817,
        field_y: 2448,
        index_x: 3816.382909165213,
        index_y: 2445.960525573382,
        index_name: "32    Ori"
      }
    },
    {
      Match: {
        field: 95,
        star_idx: 27,
        logodds: 4.9026845582728935,
        field_x: 3937,
        field_y: 2456,
        index_x: 3933.9723042160094,
        index_y: 2453.2111078933244,
        index_name: "Bellatrix"
      }
    },
    {
      Match: {
        field: 96,
        star_idx: 672,
        logodds: 4.930194283299658,
        field_x: 5019,
        field_y: 2480,
        index_x: 5016.408753354853,
        index_y: 2477.825484348289,
        index_name: "88    Tau"
      }
    },
    {
      Match: {
        field: 97,
        star_idx: 768,
        logodds: 4.8585828020092166,
        field_x: 4679,
        field_y: 2485,
        index_x: 4674.570145264683,
        index_y: 2482.521822880525,
        index_name: "2Pi 2Ori"
      }
    },
    {
      Match: {
        field: 98,
        star_idx: 261,
        logodds: 4.984974237507492,
        field_x: 5867,
        field_y: 2502,
        index_x: 5867.360276316907,
        index_y: 2501.4019697419303,
        index_name: "35Lam Tau"
      }
    },
    {
      Match: {
        field: 99,
        star_idx: 913,
        logodds: 4.955908409727529,
        field_x: 3611,
        field_y: 2529,
        index_x: 3609.607214944915,
        index_y: 2526.9118040370786,
        index_name: "47Ome Ori"
      }
    },
    {
      Match: {
        field: 100,
        star_idx: 1799,
        logodds: 4.55560689145403,
        field_x: 2438,
        field_y: 2553,
        index_x: 2428.8005984872725,
        index_y: 2554.3153925672095,
        index_name: "HR2395"
      }
    },
    {
      Match: {
        field: 101,
        star_idx: 1596,
        logodds: -0.272909429657858,
        field_x: 1776,
        field_y: 2577,
        index_x: 1744.0750858888896,
        index_y: 2582.7460817679066,
        index_name: "19    Mon"
      }
    },
    "Distractor",
    {
      Match: {
        field: 103,
        star_idx: 1206,
        logodds: 4.954379430098875,
        field_x: 3301,
        field_y: 2609,
        index_x: 3299.43096015355,
        index_y: 2606.964241496181,
        index_name: "56    Ori"
      }
    },
    {
      Match: {
        field: 104,
        star_idx: 1825,
        logodds: 4.948166992365376,
        field_x: 5256,
        field_y: 2612,
        index_x: 5253.780570126489,
        index_y: 2610.290386633562,
        index_name: "66    Tau"
      }
    },
    {
      Match: {
        field: 105,
        star_idx: 210,
        logodds: 4.847500040334838,
        field_x: 4645,
        field_y: 2643,
        index_x: 4640.835978992235,
        index_y: 2639.737730740056,
        index_name: "1Pi 3Ori"
      }
    },
    "Distractor",
    {
      Match: {
        field: 107,
        star_idx: 1593,
        logodds: 4.940782051461694,
        field_x: 3905,
        field_y: 2684,
        index_x: 3904.0409594848666,
        index_y: 2681.100719038556,
        index_name: "23    Ori"
      }
    },
    {
      Match: {
        field: 108,
        star_idx: 1005,
        logodds: 4.933718327396326,
        field_x: 3817,
        field_y: 2691,
        index_x: 3815.798213273255,
        index_y: 2687.9513939605245,
        index_name: "30Psi2Ori"
      }
    },
    {
      Match: {
        field: 109,
        star_idx: 1437,
        logodds: 4.961355608408045,
        field_x: 3477,
        field_y: 2706,
        index_x: 3476.3809827438668,
        index_y: 2703.8027686453943,
        index_name: "51    Ori"
      }
    },
    {
      Match: {
        field: 110,
        star_idx: 691,
        logodds: 4.973211925025849,
        field_x: 5427,
        field_y: 2714,
        index_x: 5426.624220468415,
        index_y: 2712.3572546485548,
        index_name: "49Mu  Tau"
      }
    },
    "Distractor",
    {
      Match: {
        field: 112,
        star_idx: 352,
        logodds: 4.805312744630797,
        field_x: 4583,
        field_y: 2741,
        index_x: 4578.48387026393,
        index_y: 2736.997087652764,
        index_name: "3Pi 4Ori"
      }
    },
    {
      Match: {
        field: 113,
        star_idx: 1450,
        logodds: 4.81350276747745,
        field_x: 2647,
        field_y: 2780,
        index_x: 2641.290883886161,
        index_y: 2781.478769685022,
        index_name: "HR2275"
      }
    },
    "Distractor",
    {
      Match: {
        field: 115,
        star_idx: 1419,
        logodds: 4.908317235421105,
        field_x: 3824,
        field_y: 2801,
        index_x: 3822.609895616747,
        index_y: 2797.273611996442,
        index_name: "25Psi1Ori"
      }
    },
    {
      Match: {
        field: 116,
        star_idx: 871,
        logodds: 4.921786139671918,
        field_x: 4071,
        field_y: 2802,
        index_x: 4069.963358340962,
        index_y: 2798.528684714164,
        index_name: "17Rho Ori"
      }
    },
    {
      Match: {
        field: 117,
        star_idx: 1731,
        logodds: 4.37809991860305,
        field_x: 2443,
        field_y: 2864,
        index_x: 2432.5634391564877,
        index_y: 2867.5970665894765,
        index_name: "10    Mon"
      }
    },
    {
      Match: {
        field: 118,
        star_idx: 74,
        logodds: 4.935072821866893,
        field_x: 3626,
        field_y: 2914,
        index_x: 3626.772765871101,
        index_y: 2910.8583073953087,
        index_name: "Mintaka"
      }
    },
    {
      Match: {
        field: 119,
        star_idx: 1527,
        logodds: 4.963961038900813,
        field_x: 3436,
        field_y: 2915,
        index_x: 3436.357810465978,
        index_y: 2912.8641370572373,
        index_name: "HR1952"
      }
    },
    {
      Match: {
        field: 120,
        star_idx: 948,
        logodds: 4.969470346281351,
        field_x: 3020,
        field_y: 2929,
        index_x: 3018.109373550498,
        index_y: 2928.8831875935484,
        index_name: "HR2113"
      }
    },
    {
      Match: {
        field: 121,
        star_idx: 30,
        logodds: 4.960627174233803,
        field_x: 3522,
        field_y: 2953,
        index_x: 3522.2827411862336,
        index_y: 2950.7028827797903,
        index_name: "Alnilam"
      }
    },
    {
      Match: {
        field: 122,
        star_idx: 365,
        logodds: 4.893150674871663,
        field_x: 4438,
        field_y: 2968,
        index_x: 4435.695647261265,
        index_y: 2964.320116509219,
        index_name: "8Pi 5Ori"
      }
    },
    {
      Match: {
        field: 123,
        star_idx: 32,
        logodds: 4.9554385536184675,
        field_x: 3417,
        field_y: 2978,
        index_x: 3415.685483558973,
        index_y: 2975.8398084979253,
        index_name: "Alnitak"
      }
    },
    {
      Match: {
        field: 124,
        star_idx: 1145,
        logodds: 4.93021988307196,
        field_x: 3649,
        field_y: 2991,
        index_x: 3648.7581978213575,
        index_y: 2987.6266501215214,
        index_name: "31    Ori"
      }
    },
    {
      Match: {
        field: 125,
        star_idx: 1156,
        logodds: 4.942221095968781,
        field_x: 3821,
        field_y: 2992,
        index_x: 3820.6634418064305,
        index_y: 2989.0126069175963,
        index_name: "22    Ori"
      }
    },
    {
      Match: {
        field: 126,
        star_idx: 879,
        logodds: 4.887594900336888,
        field_x: 4332,
        field_y: 2995,
        index_x: 4330.551432311784,
        index_y: 2990.77337487297,
        index_name: "10Pi 6Ori"
      }
    },
    {
      Match: {
        field: 127,
        star_idx: 387,
        logodds: 3.916461753701872,
        field_x: 2359,
        field_y: 3034,
        index_x: 2346.1079969195184,
        index_y: 3040.927245420844,
        index_name: "11Bet Mon"
      }
    },
    {
      Match: {
        field: 128,
        star_idx: 394,
        logodds: 4.957390336144833,
        field_x: 3436,
        field_y: 3043,
        index_x: 3435.8616997133004,
        index_y: 3040.5535924593228,
        index_name: "48Sig Ori"
      }
    },
    {
      Match: {
        field: 129,
        star_idx: 516,
        logodds: 4.6978997264589495,
        field_x: 2648,
        field_y: 3072,
        index_x: 2641.516992179309,
        index_y: 3075.9839126272514,
        index_name: "5Gam Mon"
      }
    },
    {
      Match: {
        field: 130,
        star_idx: 1730,
        logodds: 4.7222863471028145,
        field_x: 2697,
        field_y: 3114,
        index_x: 2691.0600604770966,
        index_y: 3118.2119851492853,
        index_name: "HR2205"
      }
    },
    {
      Match: {
        field: 131,
        star_idx: 247,
        logodds: 4.935254970134283,
        field_x: 3715,
        field_y: 3129,
        index_x: 3714.3264780701456,
        index_y: 3125.8413073611327,
        index_name: "28Eta Ori"
      }
    },
    {
      Match: {
        field: 132,
        star_idx: 994,
        logodds: 4.986569379710145,
        field_x: 3438,
        field_y: 3239,
        index_x: 3438.0762415144977,
        index_y: 3238.596752077873,
        index_name: "42    Ori"
      }
    },
    "Distractor",
    {
      Match: {
        field: 134,
        star_idx: 1886,
        logodds: 4.987078597118574,
        field_x: 3425,
        field_y: 3283,
        index_x: 3424.841395877798,
        index_y: 3282.796465268176,
        index_name: "41The1Ori"
      }
    },
    {
      Match: {
        field: 135,
        star_idx: 123,
        logodds: 4.986897875534989,
        field_x: 3407,
        field_y: 3323,
        index_x: 3407.2329516871014,
        index_y: 3322.779867660295,
        index_name: "Hatsya"
      }
    },
    {
      Match: {
        field: 136,
        star_idx: 1224,
        logodds: 4.970985725870676,
        field_x: 3305,
        field_y: 3400,
        index_x: 3304.163411786906,
        index_y: 3401.607845691865,
        index_name: "49    Ori"
      }
    },
    {
      Match: {
        field: 137,
        star_idx: 1859,
        logodds: 4.9594485781491375,
        field_x: 3998,
        field_y: 3437,
        index_x: 3999.0085959829657,
        index_y: 3434.8610432830683,
        index_name: "66    Eri"
      }
    },
    {
      Match: {
        field: 138,
        star_idx: 1033,
        logodds: 4.9792997346665775,
        field_x: 3435,
        field_y: 3458,
        index_x: 3435.3497333292694,
        index_y: 3459.2247399531793,
        index_name: "36Ups Ori"
      }
    },
    {
      Match: {
        field: 139,
        star_idx: 127,
        logodds: 4.9695454569991835,
        field_x: 3965,
        field_y: 3463,
        index_x: 3965.7852588879487,
        index_y: 3461.2805639468543,
        index_name: "Cursa"
      }
    },
    {
      Match: {
        field: 140,
        star_idx: 524,
        logodds: 4.937266681487435,
        field_x: 4466,
        field_y: 3490,
        index_x: 4466.5559175659955,
        index_y: 3486.882373222129,
        index_name: "57Mu  Eri"
      }
    },
    "Distractor",
    {
      Match: {
        field: 142,
        star_idx: 57,
        logodds: 4.835167240794927,
        field_x: 3064,
        field_y: 3531,
        index_x: 3062.4180912563234,
        index_y: 3536.286353806097,
        index_name: "Saiph"
      }
    },
    {
      Match: {
        field: 143,
        star_idx: 321,
        logodds: 4.983628706494695,
        field_x: 3725,
        field_y: 3529,
        index_x: 3725.869538907474,
        index_y: 3529.0209368868927,
        index_name: "20Tau Ori"
      }
    },
    {
      Match: {
        field: 144,
        star_idx: 599,
        logodds: 4.977442690615879,
        field_x: 3575,
        field_y: 3558,
        index_x: 3575.4577279086197,
        index_y: 3559.335733074434,
        index_name: "29    Ori"
      }
    },
    {
      Match: {
        field: 145,
        star_idx: 483,
        logodds: 4.9572983382752955,
        field_x: 4655,
        field_y: 3571,
        index_x: 4656.9360260060585,
        index_y: 3569.4919419406515,
        index_name: "48Nu  Eri"
      }
    },
    {
      Match: {
        field: 146,
        star_idx: 6,
        logodds: 4.96440539616003,
        field_x: 3746,
        field_y: 3663,
        index_x: 3748.126301877768,
        index_y: 3663.2826846521816,
        index_name: "Rigel"
      }
    },
    "Distractor",
    "Distractor",
    {
      Match: {
        field: 149,
        star_idx: 674,
        logodds: 4.940804133263754,
        field_x: 3837,
        field_y: 3749,
        index_x: 3839.00655629207,
        index_y: 3751.301065932659,
        index_name: "69Lam Eri"
      }
    },
    {
      Match: {
        field: 150,
        star_idx: 1100,
        logodds: 2.394495375768887,
        field_x: 2554,
        field_y: 3830,
        index_x: 2542.1502710675245,
        index_y: 3849.44724717636,
        index_name: "18The Lep"
      }
    },
    {
      Match: {
        field: 151,
        star_idx: 366,
        logodds: 3.5611194277329794,
        field_x: 2765,
        field_y: 3836,
        index_x: 2757.57004317469,
        index_y: 3851.167673544056,
        index_name: "16Eta Lep"
      }
    },
    {
      Match: {
        field: 152,
        star_idx: 306,
        logodds: 3.6093105117425743,
        field_x: 2926,
        field_y: 3960,
        index_x: 2920.947775532475,
        index_y: 3975.8145121686302,
        index_name: "14Zet Lep"
      }
    },
    "Distractor",
    "Distractor",
    {
      Match: {
        field: 155,
        star_idx: 708,
        logodds: 4.476265666560488,
        field_x: 3504,
        field_y: 4033,
        index_x: 3505.484040133237,
        index_y: 4043.001254154684,
        index_name: "6Lam Lep"
      }
    },
    {
      Match: {
        field: 156,
        star_idx: 785,
        logodds: 4.447445230338975,
        field_x: 3635,
        field_y: 4064,
        index_x: 3637.6173478779574,
        index_y: 4074.056890532896,
        index_name: "4Kap Lep"
      }
    },
    "Distractor"
  ],
  logodds: 584.7403845376231,
  best_idx: 157,
  best_logodds: 584.7403845376231
};
export default res;
