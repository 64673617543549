import React from "../../_snowpack/pkg/react.js";
import {useDispatch, useSelector} from "../../_snowpack/pkg/react-redux.js";
import cuid from "../../_snowpack/pkg/cuid.js";
import localforage from "../../_snowpack/pkg/localforage.js";
import {Button, CircularProgress, Drawer, IconButton, ImageList, ImageListItem, ImageListItemBar} from "../../_snowpack/pkg/@mui/material.js";
import {Photo as PhotoIcon, Replay as ReplayIcon} from "../../_snowpack/pkg/@mui/icons-material.js";
import imagesSlice, {selectors} from "../reducers/imagesSlice.js";
import {rootSelector as walkthroughSelector} from "../reducers/walkthroughSlice.js";
import {Spinner} from "./spinner.js";
import {authApi, imageQueryApi} from "../api.js";
import {ImageCanvas} from "../model/ImageCanvas.js";
const drawerWidth = 240;
function ImageListComponent() {
  const dispatch = useDispatch();
  const images = useSelector(selectors.selectAll);
  const {run: walkthroughIsRunning} = useSelector(walkthroughSelector);
  const remainingQueriesResult = imageQueryApi.endpoints.getRemainingQueryCount.useQueryState();
  const isSignedIn = !!authApi.endpoints.me.useQueryState().data;
  const isAllowedToQuery = remainingQueriesResult.data?.unlimited || (remainingQueriesResult.data?.remaining ?? 0) < 0;
  const handleFileInput = async (e) => {
    if (e.target.files === null) {
      return;
    }
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files.item(i);
      if (file === null) {
        continue;
      }
      if (!file.type.startsWith("image/")) {
        continue;
      }
      const id = cuid();
      const img = new Image();
      const imgUrl = window.URL.createObjectURL(file);
      img.addEventListener("load", async (ev) => {
        const canvas = new ImageCanvas("#canvas-workbench");
        if (canvas === null || canvas.canvas === null) {
          return;
        }
        canvas.canvas.width = img.width;
        canvas.canvas.height = img.height;
        canvas.context?.drawImage(img, 0, 0);
        const imgData = await localforage.setItem(id, canvas.context?.getImageData(0, 0, img.width, img.height));
        const image = {
          id,
          fileName: file.name,
          fileType: file.type,
          width: img.width,
          height: img.height,
          imgUrl,
          isLandscape: img.width >= img.height,
          processedId: null,
          extractedPoints: null,
          isWaiting: true,
          imageQueryResponse: null,
          error: null,
          isWalkthrough: false
        };
        dispatch(imagesSlice.actions.imageAdded(image));
      });
      img.src = imgUrl;
    }
  };
  return /* @__PURE__ */ React.createElement(Drawer, {
    sx: {
      width: drawerWidth,
      flexShrink: 0,
      "& .MuiDrawer-paper": {
        top: "inherit",
        width: drawerWidth,
        boxSizing: "border-box"
      }
    },
    variant: "permanent",
    anchor: "left"
  }, /* @__PURE__ */ React.createElement(ImageList, {
    id: "image-list",
    cols: 1,
    sx: {width: drawerWidth, bgcolor: "background.paper"}
  }, /* @__PURE__ */ React.createElement(Button, {
    className: "walkthrough-target-import-image",
    onClick: () => document.getElementById("fileElem")?.click(),
    variant: "contained",
    color: "info",
    sx: {
      m: 1,
      width: "-webkit-fill-available"
    },
    disabled: walkthroughIsRunning,
    size: "large",
    endIcon: /* @__PURE__ */ React.createElement(PhotoIcon, null)
  }, "Import Image"), /* @__PURE__ */ React.createElement("input", {
    style: {display: "none"},
    type: "file",
    id: "fileElem",
    multiple: true,
    accept: "image/*",
    className: "hidden",
    onChange: handleFileInput
  }), images.map((image) => /* @__PURE__ */ React.createElement(ImageListItem, {
    key: image.id,
    sx: (theme) => ({
      m: 1,
      mt: 0,
      width: drawerWidth - Number(theme.spacing(1)) * 2,
      border: 2,
      borderColor: "grey.500",
      borderRadius: 1,
      overflow: "hidden",
      cursor: "pointer",
      "&:hover": {
        borderColor: "grey.300"
      }
    }),
    className: "m-1 relative hover:opacity-80 cursor-pointer walkthrough-target-image-list",
    onClick: () => dispatch(imagesSlice.actions.imageSelected(image.id))
  }, /* @__PURE__ */ React.createElement("img", {
    src: image.imgUrl,
    className: "rounded-md"
  }), image.isWaiting ? /* @__PURE__ */ React.createElement(CircularProgress, {
    sx: (theme) => ({
      position: "absolute",
      top: 20,
      left: 90
    })
  }) : "", !image.isWaiting && !!image.error && isAllowedToQuery ? /* @__PURE__ */ React.createElement(IconButton, {
    color: "secondary",
    sx: {
      zIndex: 10,
      position: "absolute",
      width: 32,
      right: 0
    },
    onClick: () => dispatch(imagesSlice.actions.imageQueryRequestDispatched(image.id))
  }, /* @__PURE__ */ React.createElement(ReplayIcon, null)) : "", /* @__PURE__ */ React.createElement(ImageListItemBar, {
    title: image.fileName,
    subtitle: getSubtitle(image, isSignedIn, isAllowedToQuery)
  })))));
}
export default ImageListComponent;
function getSubtitle(image, isSignedIn, isAllowedToQuery) {
  if (image.isWaiting) {
    if (image.extractedPoints === null) {
      return "Extracting pts";
    }
    if (image.imageQueryResponse === null) {
      return "matching";
    }
    return "waiting";
  }
  if (image.error) {
    return `Error: ${humanizeError(image.error)}`;
  }
  if (image.imageQueryResponse === null) {
    if (image.isWalkthrough) {
      return "";
    }
    if (!isSignedIn) {
      return "Sign in to solve";
    }
    if (!isAllowedToQuery) {
      return "Daily limit reached.";
    }
    return "";
  } else {
    return "Solve request complete";
  }
}
function ImageListStatus(props) {
  const {image} = props;
  let statusText = "";
  let color = "text-gray-400";
  let waiting = false;
  let good = false;
  if (image.imageQueryResponse === null) {
    if (image.extractedPoints === null) {
      statusText = "Extracting pts";
      waiting = true;
    } else {
      statusText = "matching";
      waiting = true;
    }
  } else {
    statusText = "";
    color = "text-green-400";
    good = true;
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: `absolute ${color} top-6 w-full flex justify-around align-middle`
  }, waiting && /* @__PURE__ */ React.createElement(Spinner, null)), /* @__PURE__ */ React.createElement("div", {
    className: `absolute ${color} top-0 m-1 w-full flex justify-start align-middle`
  }, good && /* @__PURE__ */ React.createElement("svg", {
    className: "h-5 w-5",
    viewBox: "0 0 20 20",
    fill: "currentColor"
  }, /* @__PURE__ */ React.createElement("path", {
    fillRule: "evenodd",
    d: "M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z",
    clipRule: "evenodd"
  }))), /* @__PURE__ */ React.createElement("div", {
    className: `absolute ${color} top-0 text-center w-full text-xs`
  }, statusText));
}
function humanizeError(err) {
  switch (err) {
    case "429":
      return "not enough credit";
    case "401":
      return "not signed in";
    default:
      return err;
  }
}
