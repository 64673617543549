import {createSlice} from "../../_snowpack/pkg/@reduxjs/toolkit.js";
const slice = createSlice({
  name: "toast",
  initialState: {notifications: []},
  reducers: {
    enqueueSnackbar: (state, {payload: notification}) => {
      state.notifications.push(notification);
    },
    closeSnackbar: (state, {payload: key}) => {
      state.notifications = state.notifications.map((x) => x.key !== key ? x : {...x, dismissed: true});
    },
    removeSnackbar: (state, {payload: key}) => {
      state.notifications = state.notifications.filter((x) => x.key !== key);
    }
  }
});
export default slice;
export const {actions} = slice;
